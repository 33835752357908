import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Skeleton,
    useMediaQuery
} from "@mui/material";
import dummy from "../../assets/DummyImage.png";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import "./Sidebar.css";

const Sidebar = ({ onSelectCategory, categorydata, selectedCategory, categoryRefs, isLoading, setShowBogoPopup, setSelectedBogoCategoryId }) => {
    const [open, setOpen] = useState(false);
    const [openSubcategories, setOpenSubcategories] = useState({});
    const isMobile = useMediaQuery("(max-width:850px)");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggleSubcategory = (categoryId) => {
        setOpenSubcategories((prevState) => ({
            // ...prevState,
            [categoryId]: !prevState[categoryId],
        }));
    };

    useEffect(() => {
        if (!isMobile && open) {
            handleClose(); // Close the dialog when screen width exceeds 850px
        }
    }, [isMobile, open]); // Re-run effect when screen size changes or dialog state changes

    const renderSkeletons = () => {
        return Array.from({ length: 6 }).map((_, index) => (
          <ListItem key={index} className="sidebar-item">
            <Skeleton
              variant="rectangular"
              width={40}
              height={40}
              style={{ marginRight: "10px" }}
              sx={{bgcolor: "#d3d3d3"}}
            />
            <Skeleton variant="text" width="60%" height={20} sx={{bgcolor: "#d3d3d3"}} />
          </ListItem>
        ));
      };

    return (
        <>
            <Box className="sidebar">
                <Box className="sidebar-header">
                    <ListItemIcon className="sidebar-icon">
                        <MenuBookIcon />
                    </ListItemIcon>
                    <Typography variant="h6" className="sidebar-title">
                        What are you craving for?
                    </Typography>
                </Box>
                <List component="nav">
                    {isLoading
                        ? renderSkeletons()
                        : categorydata.map((item, index) => (
                              <React.Fragment key={index}>
                                  {(item.items.length > 0 ||
                                      (item.subCategories.length > 0 &&
                                          item.subCategories.some(
                                              (sub) => sub.items.length > 0
                                          ))) && (
                                      <ListItem
                                          button
                                          className={
                                              selectedCategory
                                                  ? item.refId === selectedCategory
                                                      ? "sidebar-item active"
                                                      : "sidebar-item"
                                                  : index === 0
                                                  ? "sidebar-item active"
                                                  : "sidebar-item"
                                          }
                                          onClick={() => {
                                              onSelectCategory(item.refId);
                                              if (item.linkoffer == "1") {
                                                  setShowBogoPopup(true);
                                                  setSelectedBogoCategoryId(item.refId);
                                              }
                                              handleClose(); // Close sidebar on item click (for mobile)
                                          }}
                                      >
                                          <img
                                              src={
                                                  item.imgUrl &&
                                                  item.imgUrl !== null &&
                                                  item.imgUrl !== ""
                                                      ? item.imgUrl
                                                      : dummy
                                              }
                                              onError={(e) => {
                                                  e.target.src = dummy;
                                              }}
                                              alt={item.title}
                                              style={{
                                                  width: "40px",
                                                  height: "40px",
                                                  marginRight: "10px",
                                                  borderRadius: "5px",
                                              }}
                                              loading="lazy"
                                          />
                                          <ListItemText
                                              primary={item.title}
                                              className="nav-category-list"
                                          />
                                          {item.subCategories &&
                                          item.subCategories.some((sub) => sub.items.length > 0) ? (
                                              openSubcategories[item.refId] ? (
                                                  <ExpandLess
                                                      onClick={(e) => {
                                                          e.stopPropagation(); // Prevent triggering ListItem onClick
                                                          handleToggleSubcategory(item.refId);
                                                      }}
                                                  />
                                              ) : (
                                                  <ExpandMore
                                                      onClick={(e) => {
                                                          e.stopPropagation(); // Prevent triggering ListItem onClick
                                                          handleToggleSubcategory(item.refId);
                                                      }}
                                                  />
                                              )
                                          ) : null}
                                      </ListItem>
                                  )}
                                  {item.subCategories && item.subCategories.length > 0 && (
                                      <Collapse
                                          in={openSubcategories[item.refId]}
                                          timeout="auto"
                                          unmountOnExit
                                          key={index}
                                      >
                                          <List component="div" disablePadding>
                                              {item.subCategories.map((subItem, index) => (
                                                  <>
                                                      {subItem.items.length > 0 && (
                                                          <ListItem
                                                              button
                                                              key={index}
                                                              className={
                                                                  selectedCategory === subItem.refId
                                                                      ? "sidebar-item active"
                                                                      : "sidebar-item"
                                                              }
                                                              onClick={() => {
                                                                  onSelectCategory(subItem.refId);
                                                                  if (item.linkoffer == "1") {
                                                                      setShowBogoPopup(true);
                                                                      setSelectedBogoCategoryId(
                                                                          item.refId
                                                                      );
                                                                  }
                                                                  handleClose(); // Close sidebar on item click (for mobile)
                                                              }}
                                                          >
                                                              <ListItemText
                                                                  primary={subItem.title}
                                                                  className="nav-category-list"
                                                                  style={{
                                                                      marginLeft: "20px",
                                                                      fontSize: "small",
                                                                  }}
                                                              />
                                                          </ListItem>
                                                      )}
                                                  </>
                                              ))}
                                          </List>
                                      </Collapse>
                                  )}
                              </React.Fragment>
                          ))}
                </List>
            </Box>

            {/* Modal Dialog for Mobile */}
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                sx={{
                    "& .MuiDialog-paper": {
                        width: "80%", // Set a smaller width
                        maxWidth: "90%", // Ensure responsiveness
                        borderRadius: "20px",
                        height: "90%", // Set a smaller height
                    },
                }}
            >
                <DialogTitle className="sidebar-header">
                    <ListItemIcon className="sidebar-icon">
                        <MenuBookIcon />
                    </ListItemIcon>
                    <Typography variant="h6" className="sidebar-title">
                        What are you craving for?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <List component="nav">
                        {isLoading
                            ? renderSkeletons()
                            : categorydata.map((item, index) => (
                                  <React.Fragment key={index}>
                                      {(item.items.length > 0 || item.subCategories.length > 0) && (
                                          <ListItem
                                              button
                                              className={
                                                  selectedCategory
                                                      ? item.refId === selectedCategory
                                                          ? "sidebar-item active"
                                                          : "sidebar-item"
                                                      : index === 0
                                                      ? "sidebar-item active"
                                                      : "sidebar-item"
                                              }
                                              onClick={() => {
                                                  onSelectCategory(item.refId);
                                                  if (item.linkoffer == "1") {
                                                      setShowBogoPopup(true);
                                                      setSelectedBogoCategoryId(item.refId);
                                                  }
                                                  handleClose(); // Close sidebar on item click (for mobile)
                                              }}
                                          >
                                              <img
                                                  src={
                                                      item.imgUrl &&
                                                      item.imgUrl !== null &&
                                                      item.imgUrl !== ""
                                                          ? item.imgUrl
                                                          : dummy
                                                  }
                                                  onError={(e) => {
                                                      e.target.src = dummy;
                                                  }}
                                                  alt={item.title}
                                                  style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      marginRight: "10px",
                                                      borderRadius: "5px",
                                                  }}
                                                  loading="lazy"
                                              />
                                              <ListItemText
                                                  primary={item.title}
                                                  className="nav-category-list"
                                              />
                                              {item.subCategories &&
                                              item.subCategories.some(
                                                  (sub) => sub.items.length > 0
                                              ) ? (
                                                  openSubcategories[item.refId] ? (
                                                      <ExpandLess
                                                          onClick={(e) => {
                                                              e.stopPropagation(); // Prevent triggering ListItem onClick
                                                              handleToggleSubcategory(item.refId);
                                                          }}
                                                      />
                                                  ) : (
                                                      <ExpandMore
                                                          onClick={(e) => {
                                                              e.stopPropagation(); // Prevent triggering ListItem onClick
                                                              handleToggleSubcategory(item.refId);
                                                          }}
                                                      />
                                                  )
                                              ) : null}
                                          </ListItem>
                                      )}
                                      {item.subCategories && item.subCategories.some((sub) => sub.items.length > 0) && (
                                          <Collapse
                                              in={openSubcategories[item.refId]}
                                              timeout="auto"
                                              unmountOnExit
                                              key={index}
                                          >
                                              <List component="div" disablePadding>
                                                  {item.subCategories.map((subItem, index) => (
                                                    <>
                                                      {subItem.items.length > 0 && (
                                                        <ListItem
                                                            button
                                                            key={index}
                                                            className={
                                                                selectedCategory === subItem.refId
                                                                    ? "sidebar-item active"
                                                                    : "sidebar-item"
                                                            }
                                                            onClick={() => {
                                                                onSelectCategory(subItem.refId);
                                                                if (item.linkoffer == "1") {
                                                                    setShowBogoPopup(true);
                                                                    setSelectedBogoCategoryId(
                                                                        item.refId
                                                                    );
                                                                }
                                                                handleClose(); // Close sidebar on item click (for mobile)
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={subItem.title}
                                                                className="nav-category-list"
                                                                style={{
                                                                    marginLeft: "20px",
                                                                    fontSize: "small",
                                                                }}
                                                            />
                                                        </ListItem>
                                                      )}
                                                    </>
                                                  ))}
                                              </List>
                                          </Collapse>
                                      )}
                                  </React.Fragment>
                              ))}
                    </List>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", padding: "6px" }}>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            backgroundColor: "#a3d6b3", // Light grey background
                            "&:hover": {
                                backgroundColor: "#91ab99", // Slightly darker on hover
                            },
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CloseIcon sx={{ fontSize: 21, color: "#333" }} />
                    </IconButton>
                </DialogActions>
            </Dialog>

            {/* Button to Open Sidebar Modal (Mobile Only) */}
            <Button
                variant="contained"
                color="primary"
                className="sidebar-toggle-button"
                onClick={handleOpen}
                sx={{
                    display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                    },
                }} // Show only on mobile screens
            >
                {/* <LocalPizzaIcon /> */}
                Menu
            </Button>
        </>
    );
};

export default Sidebar;
