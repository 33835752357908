import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cartData: [], // Will store only individual item related data
  totalCartItems: 0,
  totalCartQuantity: 0,
  totalTax: [],
  // totalSGST: 0,
  // totalCGST: 0,
  totalDiscount: 0,
  totalAmount: 0,
  totalAmountWithTax: 0,
  totalItemLevelCharge: 0,
  orderLevelCharge: 0,
  isDeliveryOrder: true,
}

const getItemLevelTax = (itemid) => {
  let menuData = JSON.parse(localStorage.getItem('menuData'));
  let taxData = menuData.taxes
    .filter(tax => tax.item_ref_ids.includes(itemid.toString()) && (tax.active == 1 || tax.active == true))
    .map(tax => ({
      "code": tax.code,
      "title": tax.title,
      "description": tax.description,
      "active": tax.active,
      "structure": {
        "value": tax.structure.value
      }
    }));
  return taxData;
};

const calculateFinalCartData = (state, charges, action) => {
  state.totalTax = {}; // Reset total tax
  state.totalAmount = 0;
  state.totalAmountWithTax = 0;
  state.totalDiscount = 0; // Reset total discount
  state.totalCartItems = 0;
  state.totalCartQuantity = 0;

  let totalItemLevelCharge = 0;
  let totalOrderLevelCharge = 0;

  state?.cartData?.forEach((item) => {
      let modifierPrice = 0;
      if (item.optionsToAdd?.length > 0) {
          modifierPrice = item.optionsToAdd.slice(1).reduce((acc, mod) => acc + mod.price, 0);
      }
      // const originalPrice = (item.totalPrice - modifierPrice) * item.quantity;
      const basePrice = (item.totalPrice - modifierPrice) * item.quantity;
      const discountedBasePrice =
          item.offerId > 0 ? (basePrice * (1 - Number(item.discount) / 100)).toFixed(2) : basePrice;

      // Add the full modifier price (no discount applied) back to the total.
      const finalPrice = parseFloat(discountedBasePrice) + modifierPrice * item.quantity;
      const itemTotal = parseFloat(finalPrice);

      state.totalAmount = parseFloat((state.totalAmount + itemTotal).toFixed(2));

      item.itemLevelTax.forEach((tax) => {
          const discountedItemTotal = parseFloat(discountedBasePrice) + modifierPrice * item.quantity;
          const taxAmount = parseFloat(((discountedItemTotal * tax.structure.value) / 100).toFixed(3));
          if (!state.totalTax[tax.title]) {
              state.totalTax[tax.title] = 0;
          }
          state.totalTax[tax.title] = parseFloat(
              (state.totalTax[tax.title] + taxAmount).toFixed(3)
          );
      });

      state.totalCartQuantity += item.quantity;
      state.totalCartItems += 1;

      //   Below code is use for calculation of Delivery Charge and Packaging charge
      if (action === 'additem') {
          if (charges?.length > 0 && (item.packagingCharge === undefined)) {
              for (let i = 0; i < charges.length; i++) {
                  const storeCharge = charges[i];

                  // Delivery Charge (applicableon == 2)
                //   if (storeCharge.applicableon == 2) {
                //       let isDeliveryChargeApplied = storeCharge?.productjson.some(chargeitem =>
                //           chargeitem == item.refId
                //       );
                //       if (isDeliveryChargeApplied) {
                //           state.orderLevelCharge = Number(storeCharge.chargevalue);
                //           item.isOrderLevelChargedProduct = true;
                //       } else {
                //           item.isOrderLevelChargedProduct = false;
                //       }
                //   }

                  // Item level Charge (applicableon == 1)
                  if (storeCharge.applicableon == 1) {
                      let isPackagingChargeApplied = storeCharge?.productjson.some(chargeitem =>
                          chargeitem == item.refId
                      );
                      if (isPackagingChargeApplied) {
                          item.packagingCharge = Number(storeCharge.chargevalue)
                      } else {
                          item.packagingCharge = 0;
                      }
                  }
              }
          }
      } 

      totalItemLevelCharge += parseFloat(((item.packagingCharge || 0) * item.quantity).toFixed(2));
      state.totalItemLevelCharge = totalItemLevelCharge;
  });

  if(state.cartData.length == 0){
    state.orderLevelCharge = 0;
    state.totalItemLevelCharge = 0;
  }

    // let hasDeliveryCharge = state.cartData.some(cartItem => cartItem.isOrderLevelChargedProduct === true);
    // // If no items have the delivery charge, reset orderLevelCharge to 0
    // if (!hasDeliveryCharge) {
    //     state.orderLevelCharge = 0;
    // }
    

  state.totalDiscount = parseFloat(
      state.cartData
          .reduce(
              (total, item) => total + (Number(item.discount) / 100) * item.price * item.quantity,
              0
          )
          .toFixed(3)
  );

  // Calculate total amount with tax
  const totalTaxAmount = Object.values(state.totalTax).reduce((acc, val) => acc + val, 0);
  state.totalAmountWithTax = parseFloat((state.totalAmount + totalTaxAmount).toFixed(2));
};

const calculateItemLevelTax = (item, data) => {
  if (!Array.isArray(item)) {
    return 0;
  }
  let itemTotalTax = 0;
  item.forEach(tax => {
    const taxAmount = parseFloat(((data.totalPrice * tax.structure.value / 100) * (data.quantity || 1)).toFixed(3));
    itemTotalTax = parseFloat((itemTotalTax + taxAmount).toFixed(3));
  });
  return itemTotalTax;
};

const checkExistingProduct = (state, data) => {
  let existingProductIndex = -1;

    const existingProduct = state?.cartData?.find((item, index) => {
        if (item.refId == data.refId) {
            if (data.optionsToAdd.length > 0) {
                if (item.optionsToAdd.length > 0) {
                    if (data.optionsToAdd[0].refId == item.optionsToAdd[0].refId) {
                        if (data.optionsToAdd.length - 1 > 0) {
                            if (
                                item.optionsToAdd.length - 1 > 0 &&
                                item.optionsToAdd.length - 1 === data.optionsToAdd.length - 1
                            ) {
                                for (let i = 1; i < data.optionsToAdd.length; i++) {
                                    if (
                                        !item.optionsToAdd.some(
                                            (option) => option.refId == data.optionsToAdd[i].refId
                                        )
                                    ) {
                                        return false; // If any new modifier is not present, return false
                                    }
                                }
                                existingProductIndex = index;
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            if (item.optionsToAdd.length - 1 === 0) {
                                existingProductIndex = index;
                                return true;
                            } else {
                                return false;
                            }
                        }
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                if (item.optionsToAdd.length === 0) {
                    existingProductIndex = index;
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    });

    return { existingProduct, existingProductIndex };
};

function compareModifiers(modifiers1, modifiers2) {
  if (modifiers1.length !== modifiers2.length) return false;
  return modifiers1.every(mod1 => {
      return modifiers2.some(mod2 => mod1.modifierid === mod2.modifierid);
  });
}

function combineItems(items) {
  const combinedItems = [];
  
  items.forEach(item => {
      let countOfHundred = 0;
      const existingItemIndex = combinedItems.findIndex(existingItem =>
          existingItem.productid == item.productid &&
          existingItem.productportionid == item.productportionid &&
          compareModifiers(existingItem.modifiers, item.modifiers)
      );

      if (existingItemIndex > -1) {
          // If the item exists, add the quantity and update the discount if greater than 0
          if(item.offerid > 0) {
            combinedItems[existingItemIndex].offerid = item.offerid;
            combinedItems[existingItemIndex].offername = item.offername;
          }
          combinedItems[existingItemIndex].quantity += item.quantity;

          if (parseFloat(Number(item.discount)) > 0) {
            countOfHundred += item.quantity;
              combinedItems[existingItemIndex].discount = Number(item.discount);
              combinedItems[existingItemIndex].discount = (combinedItems[existingItemIndex].discount / combinedItems[existingItemIndex].quantity) * countOfHundred;
          }
      } else {
          // If the item does not exist, add it to the combinedItems array
          combinedItems.push({ ...item });
      }
  });

  return combinedItems;
}


export const CartSlice = createSlice({
  name: 'cartslice',
  initialState,
  reducers: {
    addCart: (state, action) => {
      state.cartData = action.payload;
      calculateFinalCartData(state);
      localStorage.setItem("cartData", JSON.stringify(state.cartData));
    },
    addItem: (state, action) => {
        const data = action.payload;

      const { existingProduct } = checkExistingProduct(state, data);

      let getItemTax = getItemLevelTax(data.refId);

      if (existingProduct) {
          existingProduct.quantity += 1;
          existingProduct.itemTotalTax = calculateItemLevelTax(
              existingProduct.itemLevelTax,
              existingProduct
          );
          state.totalCartQuantity += 1;
      } else {
          const itemTotalTax = calculateItemLevelTax(getItemTax, data);
          state.cartData.push({
              ...data,
              quantity: data.quantity || 1,
              itemLevelTax: getItemTax,
              itemTotalTax,
          });
          state.totalCartItems += 1;
          state.totalCartQuantity += data.quantity || 1;
      }

      calculateFinalCartData(state, action.payload.chargesArray, 'additem');
      localStorage.setItem("cartData", JSON.stringify(state.cartData));
    },


    removeItem: (state, action) => {
      const data = action.payload;
      const charges = data?.chargesArray;
      const { existingProductIndex, existingProduct } = checkExistingProduct(state, data);

      if (existingProductIndex !== -1) {
          state.totalCartQuantity -= existingProduct.quantity;
          state.totalCartItems -= 1;
          state.cartData.splice(existingProductIndex, 1);
      }

      calculateFinalCartData(state);
      localStorage.setItem("cartData", JSON.stringify(state.cartData));
    },

    increaseQty: (state, action) => {
      const data = action.payload;
      const { existingProduct } = checkExistingProduct(state, data);

      if (existingProduct) {
          existingProduct.quantity += 1;
          state.totalCartQuantity += 1;
          let itemTotalTax = calculateItemLevelTax(existingProduct.itemLevelTax, existingProduct);
          existingProduct.itemTotalTax = itemTotalTax;
      }
      calculateFinalCartData(state);
      localStorage.setItem("cartData", JSON.stringify(state.cartData));
    },

    decreaseQty: (state, action) => {
      const data = action.payload;
      const { existingProductIndex, existingProduct } = checkExistingProduct(state, data);

      if (existingProductIndex !== -1) {
          if (existingProduct.quantity === 1) {
              state.cartData.splice(existingProductIndex, 1);
              state.totalCartQuantity -= 1;
              state.totalCartItems -= 1;
          } else {
              state.totalCartQuantity -= 1;
              existingProduct.quantity -= 1;
              let itemTotalTax = calculateItemLevelTax(
                  existingProduct.itemLevelTax,
                  existingProduct
              );
              existingProduct.itemTotalTax = itemTotalTax;
          }
      }

      calculateFinalCartData(state);
      localStorage.setItem("cartData", JSON.stringify(state.cartData));
    },

    decreaseQtyFromCatalogue: (state, action) => {
      const data = action.payload;
      let existingProductIndex = -1;

      const existingProduct = state?.cartData?.find((item, index) => {
        if (item.refId == data.refId) {
          existingProductIndex = index;
          return true;
        }
      })

      if(existingProduct) {
        if (existingProduct.quantity === 1) {
          state.cartData.splice(existingProductIndex, 1);
          state.totalCartQuantity -= 1;
          state.totalCartItems -= 1;
        } else {
            state.totalCartQuantity -= 1;
            existingProduct.quantity -= 1;
            let itemTotalTax = calculateItemLevelTax(
                existingProduct.itemLevelTax,
                existingProduct
            );
            existingProduct.itemTotalTax = itemTotalTax;
        }
      }

      calculateFinalCartData(state);
      localStorage.setItem("cartData", JSON.stringify(state.cartData));
    },
    applyOrderLevelCharge: (state, action) => {
        state.orderLevelCharge = action.payload;
    },
    updateItemLevelCharge: (state, action) => {
        state.totalItemLevelCharge = action.payload;
    },
    isDeliveryOrder: (state, action) => {
        state.isDeliveryOrder = action.payload;
    },
    applyOffer: (state, action) => {
      const data = action.payload;

      const result = combineItems(data);
    
      result.forEach((item) => {
          const cartItemIndex = state.cartData.findIndex((cartItem) => {
              // Check if the product IDs match
              if (cartItem.refId == item.productid) {
                  // Check if the modifiers match
                  if (cartItem.optionsToAdd.length > 0) {
                      const productportionid =
                          cartItem?.optionsToAdd &&
                          cartItem.optionsToAdd.length > 0 &&
                          Object.keys(cartItem.optionsToAdd[0]).length > 0
                              ? cartItem.optionsToAdd[0].refId
                              : cartItem.productPortionId;

                      if (productportionid == item.productportionid || (!productportionid)) {
                          if (item.modifiers.length > 0) {
                              const modifiersMatch = item.modifiers.every((mod) =>
                                  cartItem.optionsToAdd
                                      .slice(1)
                                      .some(
                                          (existingModifier) =>
                                              existingModifier.refId == mod.modifierid
                                      )
                              );

                              if (modifiersMatch) {
                                  return true; // Return true if product ID, portion ID, and modifiers match
                              } else {
                                  return false;
                              }
                          } else {
                                if (cartItem.optionsToAdd.slice(1).length === 0) {
                                    return true;
                                } else {
                                    return false;
                                }
                          }
                      } else {
                          return false;
                      }
                  } else {
                      return true;
                  }
              } else {
                  return false;
              }
          });

          if (cartItemIndex !== -1) {
              const cartItem = state.cartData[cartItemIndex];

              // const discountedPrice = parseFloat(
              //   (item.price * (1 - item.discount / 100)).toFixed(2)
              // );


              let modifierPrice = item.modifiers.reduce((acc, mod) => acc + mod.price, 0);
              // const originalPrice = (item.totalPrice - modifierPrice) * item.quantity;
              const basePrice = item.price * item.quantity;
              const discountedBasePrice = (basePrice * (1 - Number(item.discount) / 100)).toFixed(
                  2
              );

              // Add the full modifier price (no discount applied) back to the total.
              const finalPrice = parseFloat(discountedBasePrice) + (modifierPrice * item.quantity);
              const itemTotal = parseFloat(finalPrice);

              let itemTotalTax = 0;
              cartItem.itemLevelTax.forEach((tax) => {
                  const taxAmount =
                      ((itemTotal * tax.structure.value) / 100)
                  itemTotalTax = parseFloat((itemTotalTax + taxAmount).toFixed(2));
              });

              cartItem.discount = Number(item.discount);
              cartItem.modifiers = item.modifiers;
              cartItem.offerId = item.offerid;
              cartItem.offerName = item.offername;
              cartItem.productPortionId = item.productportionid;
              cartItem.price = item.price;
              cartItem.categoryRefIds[0] = item.productcategoryid;
              cartItem.taxProfileId = item.taxprofileid;
              cartItem.portioName = item.portionname;
              cartItem.itemTotalTax = itemTotalTax;

              // Update the total price and tax
              // cartItem.totalPrice = discountedPrice * cartItem.quantity;
              // cartItem.itemTotalTax = (discountedPrice * cartItem.quantity * cartItem.producttaxrate) / 100;
          }
      });
    
      calculateFinalCartData(state);
      localStorage.setItem('cartData', JSON.stringify(state.cartData));
    },
    removeOffer: (state, action) => {
      state.cartData.map((item) => {
        item.offerId = 0;
        item.offerName = '';
        item.discount = 0;
      })
      calculateFinalCartData(state);
      localStorage.setItem('cartData', JSON.stringify(state.cartData));
    },
    addItemInstruction: (state, action) => {
        const { data, instruction } = action.payload;

        const { existingProduct } = checkExistingProduct(state, data);

        existingProduct.instructions = instruction;
        localStorage.setItem('cartData', JSON.stringify(state.cartData));
        
    },
    clearCart: (state, action) => {
        state.cartData = [];
        state.totalCartItems = 0;
        state.totalCartQuantity = 0;
        state.totalTax = 0;
        state.totalDiscount = 0;
        state.totalAmount = 0;
        state.totalAmountWithTax = 0;

        localStorage.setItem("cartData", JSON.stringify(state.cartData));
    }
  },
})

export const { addCart, addItem, removeItem, increaseQty, decreaseQty, decreaseQtyFromCatalogue, applyOrderLevelCharge, updateItemLevelCharge, isDeliveryOrder, applyOffer, removeOffer, addItemInstruction, clearCart } = CartSlice.actions

export default CartSlice.reducer
