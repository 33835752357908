import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Divider,
    Button,
    Step,
    StepLabel,
    Stepper,
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    Chip,
    ListItemText,
    CardContent,
    CardMedia,
    RadioGroup,
    Grid,
    styled,
    Paper,
    Snackbar,
    Alert,
    Card,
    ListItemAvatar,
    Avatar,
} from "@mui/material";
import OfferContext from "../../context/OfferContext";
import dummy from "../../assets/DummyImage.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import "./BOGOWizard.css";
import { addItem, removeOffer, decreaseQtyFromCatalogue } from "../../redux/slice/CartSlice";

const steps = ["Select 1st Item", "Customize 1st Item", "Select 2nd Item", "Customize 2nd Item"];

const BOGOWizard = ({
    open,
    onClose,
    bogoCategory,
    getProductQuantityInCart,
    isProductInCart,
    isStoreOpen,
    setShowPopup,
    convertOptionToPlainObject,
    setSeverity,
    setMessage,
}) => {
    const dispatch = useDispatch();
    const offerCtx = useContext(OfferContext);
    const [activeStep, setActiveStep] = useState(0);
    const [firstItem, setFirstItem] = useState();
    const [secondItem, setSecondItem] = useState();
    // const [firstPizzaAddOns, setFirstPizzaAddOns] = useState([]);
    // const [secondPizza, setSecondPizza] = useState(null);
    // const [secondPizzaAddOns, setSecondPizzaAddOns] = useState([]);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    const [selectedFirstPortion, setSelectedFirstPortion] = useState({});
    const [selectedFirstModifiers, setSelectedFirstModifiers] = useState([]);
    const [addedFirstModifiers, setAddedFirstModifiers] = useState([]);
    const [selectedSecondPortion, setSelectedSecondPortion] = useState({});
    const [selectedSecondModifiers, setSelectedSecondModifiers] = useState([]);
    const [addedSecondModifiers, setAddedSecondModifiers] = useState([]);

    const [portions, setPortions] = useState([]);
    const [firstItemportions, setFirstItemPortions] = useState([]);
    const [secondItemportions, setSecondItemPortions] = useState([]);
    const [modifiers, setModifiers] = useState([]);
    const [firstItemmodifiers, setFirstItemModifiers] = useState([]);
    const [secondItemmodifiers, setSecondItemModifiers] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("warning");
    const [firstItemSelectionStyle, SetFirstItemSelectionStyle] = useState(null);
    const [secondItemSelectionStyle, SetSecondItemSelectionStyle] = useState(null);
    const [firstItemOfferId, setFirstItemOfferId] = useState(0);
    const [secondItemOfferId, setSecondItemOfferId] = useState(0);
    const getStore = useSelector((state) => state.store?.storeData);
    const totalDiscount = useSelector((state) => state.cart?.totalDiscount);

    const handleDialogClose = () => {
        setActiveStep(0); // Reset to first step
        onClose();

        // Reset all state values
        setFirstItem(null);
        setSecondItem(null);
        SetFirstItemSelectionStyle(null);
        SetSecondItemSelectionStyle(null);
        setSelectedFirstPortion({});
        setSelectedSecondPortion({});
        setSelectedFirstModifiers([]);
        setSelectedSecondModifiers([]);
        setAddedFirstModifiers([]);
        setAddedSecondModifiers([]);
        // setPortions([]);
        setFirstItemPortions([]);
        setSecondItemPortions([]);
        // setModifiers([]);
        setFirstItemModifiers([]);
        setSecondItemModifiers([]);
    };

    useEffect(() => {
        const dialogContent = document.querySelector(".MuiDialogContent-root");
        if (dialogContent) {
            dialogContent.scrollTo({ top: 0, behavior: "smooth" });
        }

        setExpandedDescriptions({});
    }, [activeStep, open]);

    const addProductToCart = (item, firstVariationPrice, offerid) => {
        if (item.available == 1 || item.available == true) {
            const itemData = {
                available: item.available,
                currentStock: Number(item.currentStock),
                description: item.description,
                foodType: item.foodType,
                imgUrl: item.imgUrl,
                markupPrice: item.markupPrice,
                // optionGroups: item.optionGroups ? item.optionGroups.map(group => convertToPlainObject(group)) : [],
                price: item.price,
                recommended: item.recommended,
                refId: Number(item.refId),
                title: item.title,
                categoryRefIds: item.categoryRefIds,
                soldAtStore: item.soldAtStore,
                sortOrder: item.sortOrder,
                weight: item.weight,
                quantity: item.quantity || 1,
                totalPrice: Number(
                    item.price === 0
                        ? firstVariationPrice
                        : item.price === firstVariationPrice
                        ? item.price
                        : item.price + firstVariationPrice
                ),
                itemTotalTax: 0,
                offerId: offerid ? offerid : 0,
                offerName: "",
                linkOffer: offerid ? 1 : 0,
                BOGOOffer: offerid ? offerid : 0,
                instructions: "",
                discount: 0,
                productPortionId: 0,
                taxProfileId: 0,
                portionName: "",
                optionsToAdd: item.optionsToAdd
                    ? item.optionsToAdd.map((option) => convertOptionToPlainObject(option))
                    : [],
                chargesArray: getStore?.charges || [],
            };

            if (totalDiscount > 0) {
                offerCtx.resetOffer();

                const offerData = {
                    offername: "",
                    offerdescription: "",
                    isofferapplied: false,
                };
                localStorage.setItem("offerData", JSON.stringify(offerData));
                dispatch(removeOffer());
                dispatch(addItem(itemData));
            } else {
                dispatch(addItem(itemData));
            }

            // setOpen(true);
        } else {
            setMessage("Product is not available!");
            setSeverity("error");
        }
        // item.optionsToAdd = [];
    };

    const handleListItemClick = (refId, isFirst) => {
        if (isFirst) {
            if (firstItemSelectionStyle !== refId) {
                SetFirstItemSelectionStyle(refId === firstItemSelectionStyle ? null : refId); // Toggle selection
            }
        } else {
            if (secondItemSelectionStyle !== refId) {
                SetSecondItemSelectionStyle(refId === secondItemSelectionStyle ? null : refId); //Toggle selection
            }
        }
    };

    const handleNext = () => {
        let isValid = true;

        if (activeStep === 0) {
            if (!firstItem) {
                setSnackbarMessage("Please select the first item before proceeding.");
                setSnackbarSeverity("warning");
                setSnackbarOpen(true);
                return;
            }

            if (isValid) setActiveStep((prev) => prev + 1);
        } else if (activeStep === 1) {
            if (
                Number(selectedFirstPortion?.refId) > 0 &&
                (selectedFirstPortion?.available == "false" ||
                    selectedFirstPortion?.available == false)
            ) {
                setSnackbarMessage(
                    `${selectedFirstPortion.title} is not available yet. Please select another portion`
                );
                setSnackbarSeverity("warning");
                setSnackbarOpen(true);
                return;
            }

            for (let modifierGroup of selectedFirstModifiers) {
                const selectedCount = addedFirstModifiers.filter(
                    (modifier) => modifier.optGroupRefIds[0] === modifierGroup.refId
                ).length;

                if (selectedCount < modifierGroup.minSelectable) {
                    setSnackbarMessage(
                        `You must select at least ${modifierGroup.minSelectable} options from ${modifierGroup.title}.`
                    );
                    setSnackbarSeverity("warning");
                    setSnackbarOpen(true);
                    isValid = false;
                    break;
                }
            }

            if (isValid) setActiveStep((prev) => prev + 1);
        } else if (activeStep === 2) {
            if (!secondItem) {
                setSnackbarMessage("Please select the second item before proceeding.");
                setSnackbarSeverity("warning");
                setSnackbarOpen(true);
                return;
            }

            if (isValid) setActiveStep((prev) => prev + 1);
        } else if (activeStep === 3) {
            if (
                Number(selectedSecondPortion?.refId) > 0 &&
                (selectedSecondPortion?.available == "false" ||
                    selectedSecondPortion?.available == false)
            ) {
                setSnackbarMessage(
                    `${selectedSecondPortion.title} is not available yet. Please select another portion`
                );
                setSnackbarSeverity("warning");
                setSnackbarOpen(true);
                return;
            }

            for (let modifierGroup of selectedSecondModifiers) {
                const selectedCount = addedSecondModifiers.filter(
                    (modifier) => modifier.optGroupRefIds[0] === modifierGroup.refId
                ).length;

                if (selectedCount < modifierGroup.minSelectable) {
                    setSnackbarMessage(
                        `You must select at least ${modifierGroup.minSelectable} options from ${modifierGroup.title}.`
                    );
                    setSnackbarSeverity("warning");
                    setSnackbarOpen(true);
                    isValid = false;
                    break;
                }
            }

            if (isValid) {
                let array1 = [];
                let array2 = [];

                array1.splice(0, 0, selectedFirstPortion);
                array1 = array1.concat(addedFirstModifiers);

                array2.splice(0, 0, selectedSecondPortion);
                array2 = array2.concat(addedSecondModifiers);

                // Create deep copies of firstItem and secondItem before modifying
                let firstItemCopy = { ...firstItem, optionsToAdd: [...array1] };
                let secondItemCopy = { ...secondItem, optionsToAdd: [...array2] };

                // dialogItem.optionsToAdd.push(array);
                const modifierPrice1 = addedFirstModifiers.reduce(
                    (total, modifier) => total + modifier.price,
                    0
                );

                const modifierPrice2 = addedSecondModifiers.reduce(
                    (total, modifier) => total + modifier.price,
                    0
                );

                addProductToCart(
                    firstItemCopy,
                    (array1[0]?.price || 0) + modifierPrice1,
                    firstItemOfferId
                );
                addProductToCart(
                    secondItemCopy,
                    (array2[0]?.price || 0) + modifierPrice2,
                    secondItemOfferId
                );
                handleDialogClose();
                setShowPopup(true);
                // setSelectedFirstPortion({});
                // setSelectedSecondPortion({});
                // setSelectedFirstModifiers([]);
                // setSelectedSecondModifiers([]);
                // setAddedFirstModifiers([]);
                // setAddedSecondModifiers([]);
                // setPortions([]);
                // setModifiers([]);
                // handleClose();
                // setActiveStep((prev) => prev + 1);
            }
        }
    };

    const handleBack = () => setActiveStep((prev) => prev - 1);

    const handlePizzaSelection = (pizza, isFirst, offerid) => {
        if (isFirst) {
            setFirstItem(pizza);
            setFirstItemOfferId(offerid ? parseInt(offerid) : 0);
            const portionOptions = pizza.optionGroups.filter(
                (group) => group.refId === pizza.refId
            );
            const modifierOptions = pizza.optionGroups.filter(
                (group) => group.refId !== pizza.refId
            );

            if (portionOptions?.length > 0) {
                setFirstItemPortions(portionOptions[0].options);
                setSelectedFirstPortion(portionOptions[0].options[0]);

                const nestedOptGroups = portionOptions[0].options[0].nestedOptGroups;
                const selectedmod = modifierOptions.filter((m) =>
                    nestedOptGroups.includes(m.refId)
                );

                if (modifierOptions?.length > 0) {
                    setFirstItemModifiers(modifierOptions);
                    setSelectedFirstModifiers(selectedmod);

                    // Auto-select modifiers where minSelectable === maxSelectable
                    let autoSelectedModifiers = [];
                    selectedmod.forEach((group) => {
                        if (group.minSelectable === group.maxSelectable) {
                            autoSelectedModifiers = [
                                ...autoSelectedModifiers,
                                ...group.options
                                    .filter((option) => option.available === true)
                                    .slice(0, group.maxSelectable),
                            ];
                        }
                    });
                    setAddedFirstModifiers(autoSelectedModifiers);
                }
            } else {
                setFirstItemPortions([]);
                setSelectedFirstPortion({});
                setFirstItemModifiers(modifierOptions);
                setSelectedFirstModifiers(modifierOptions);
                // setAddedModifiers(modifierOptions[0].options);

                // Auto-select modifiers where minSelectable === maxSelectable
                let autoSelectedModifiers = [];
                modifierOptions.forEach((group) => {
                    if (group.minSelectable === group.maxSelectable) {
                        autoSelectedModifiers = [
                            ...autoSelectedModifiers,
                            ...group.options.slice(0, group.maxSelectable),
                        ];
                    }
                });
                setAddedFirstModifiers(autoSelectedModifiers);
            }
            // setFirstPizza(pizza);
        } else {
            setSecondItem(pizza);
            setSecondItemOfferId(offerid ? parseInt(offerid) : 0);
            const portionOptions = pizza.optionGroups.filter(
                (group) => group.refId === pizza.refId
            );
            const modifierOptions = pizza.optionGroups.filter(
                (group) => group.refId !== pizza.refId
            );

            if (portionOptions?.length > 0) {
                setSecondItemPortions(portionOptions[0].options);
                setSelectedSecondPortion(portionOptions[0].options[0]);

                const nestedOptGroups = portionOptions[0].options[0].nestedOptGroups;
                const selectedmod = modifierOptions.filter((m) =>
                    nestedOptGroups.includes(m.refId)
                );

                if (modifierOptions?.length > 0) {
                    setSecondItemModifiers(modifierOptions);
                    setSelectedSecondModifiers(selectedmod);

                    // Auto-select modifiers where minSelectable === maxSelectable
                    let autoSelectedModifiers = [];
                    selectedmod.forEach((group) => {
                        if (group.minSelectable === group.maxSelectable) {
                            autoSelectedModifiers = [
                                ...autoSelectedModifiers,
                                ...group.options
                                    .filter((option) => option.available === true)
                                    .slice(0, group.maxSelectable),
                            ];
                        }
                    });
                    setAddedSecondModifiers(autoSelectedModifiers);
                }
            } else {
                setSecondItemPortions([]);
                setSelectedSecondPortion({});
                setSecondItemModifiers(modifierOptions);
                setSelectedSecondModifiers(modifierOptions);
                // setAddedModifiers(modifierOptions[0].options);

                // Auto-select modifiers where minSelectable === maxSelectable
                let autoSelectedModifiers = [];
                modifierOptions.forEach((group) => {
                    if (group.minSelectable === group.maxSelectable) {
                        autoSelectedModifiers = [
                            ...autoSelectedModifiers,
                            ...group.options.slice(0, group.maxSelectable),
                        ];
                    }
                });
                setAddedSecondModifiers(autoSelectedModifiers);
            }
            // setSecondPizza(pizza);
        }
        // handleNext();
    };

    // const handleAddOnSelection = (addOn, isFirst) => {
    //     if (isFirst) {
    //         setFirstPizzaAddOns((prev) =>
    //             prev.includes(addOn) ? prev.filter((a) => a !== addOn) : [...prev, addOn]
    //         );
    //     } else {
    //         setSecondPizzaAddOns((prev) =>
    //             prev.includes(addOn) ? prev.filter((a) => a !== addOn) : [...prev, addOn]
    //         );
    //     }
    // };

    const toggleReadMore = (itemId) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const handlePortionChange = (refId) => {
        if (activeStep === 1) {
            const newSelectedPortion = firstItemportions.find((portion) => portion.refId === refId);
            const nestedOptGroups = newSelectedPortion.nestedOptGroups;
            const updatedModifiers = firstItemmodifiers.filter((m) =>
                nestedOptGroups.includes(m.refId)
            );

            let preservedModifiers = [];

            // Auto-select modifiers where minSelectable === maxSelectable
            updatedModifiers.forEach((group) => {
                if (
                    group.minSelectable === group.maxSelectable &&
                    group.minSelectable > 1 &&
                    group.maxSelectable > 1
                ) {
                    const alreadySelectedInGroup = preservedModifiers.filter(
                        (m) => m.optGroupRefIds[0] === group.refId
                    );

                    if (alreadySelectedInGroup.length < group.maxSelectable) {
                        const newSelections = group.options
                            .slice(0, group.maxSelectable)
                            .filter(
                                (option) =>
                                    !preservedModifiers.some((m) => m.refId === option.refId)
                            );

                        preservedModifiers = [...preservedModifiers, ...newSelections];
                    }
                } else if (group.minSelectable === group.maxSelectable) {
                    preservedModifiers = [
                        ...preservedModifiers,
                        ...group.options
                            .filter((option) => option.available === true)
                            .slice(0, group.maxSelectable),
                    ];
                }
            });

            setSelectedFirstPortion(newSelectedPortion);
            setSelectedFirstModifiers(updatedModifiers);
            setAddedFirstModifiers(preservedModifiers);
        } else {
            const newSelectedPortion = secondItemportions.find(
                (portion) => portion.refId === refId
            );
            const nestedOptGroups = newSelectedPortion.nestedOptGroups;
            const updatedModifiers = secondItemmodifiers.filter((m) =>
                nestedOptGroups.includes(m.refId)
            );

            let preservedModifiers = [];

            // Auto-select modifiers where minSelectable === maxSelectable
            updatedModifiers.forEach((group) => {
                if (
                    group.minSelectable === group.maxSelectable &&
                    group.minSelectable > 1 &&
                    group.maxSelectable > 1
                ) {
                    const alreadySelectedInGroup = preservedModifiers.filter(
                        (m) => m.optGroupRefIds[0] === group.refId
                    );

                    if (alreadySelectedInGroup.length < group.maxSelectable) {
                        const newSelections = group.options
                            .slice(0, group.maxSelectable)
                            .filter(
                                (option) =>
                                    !preservedModifiers.some((m) => m.refId === option.refId)
                            );

                        preservedModifiers = [...preservedModifiers, ...newSelections];
                    }
                } else if (group.minSelectable === group.maxSelectable) {
                    preservedModifiers = [
                        ...preservedModifiers,
                        ...group.options
                            .filter((option) => option.available === true)
                            .slice(0, group.maxSelectable),
                    ];
                }
            });

            setSelectedSecondPortion(newSelectedPortion);
            setSelectedSecondModifiers(updatedModifiers);
            setAddedSecondModifiers(preservedModifiers);
        }
    };

    const handleModifierChange = (option) => {
        if (activeStep === 1) {
            const isSelected = addedFirstModifiers.some(
                (modifier) => modifier.refId === option.refId
            );
            const maxSelectable = selectedFirstModifiers.find(
                (group) => group.refId === option.optGroupRefIds[0]
            ).maxSelectable;

            const addedModifierQuantity = addedFirstModifiers.filter(
                (m) => m.optGroupRefIds[0] === option.optGroupRefIds[0]
            ).length;

            if (!isSelected && addedModifierQuantity >= maxSelectable) {
                setSnackbarMessage(`You can only select up to ${maxSelectable} add ons.`);
                setSnackbarSeverity("warning");
                setSnackbarOpen(true);

                return;
            }

            if (isSelected) {
                setAddedFirstModifiers((prev) =>
                    prev.filter((modifier) => modifier.refId !== option.refId)
                );
            } else {
                setAddedFirstModifiers((prev) => [...prev, option]);
            }
        } else if (activeStep === 3) {
            const isSelected = addedSecondModifiers.some(
                (modifier) => modifier.refId === option.refId
            );
            const maxSelectable = selectedSecondModifiers.find(
                (group) => group.refId === option.optGroupRefIds[0]
            ).maxSelectable;

            const addedModifierQuantity = addedSecondModifiers.filter(
                (m) => m.optGroupRefIds[0] === option.optGroupRefIds[0]
            ).length;

            if (!isSelected && addedModifierQuantity >= maxSelectable) {
                setSnackbarMessage(`You can only select up to ${maxSelectable} add ons.`);
                setSnackbarSeverity("warning");
                setSnackbarOpen(true);

                return;
            }

            if (isSelected) {
                setAddedSecondModifiers((prev) =>
                    prev.filter((modifier) => modifier.refId !== option.refId)
                );
            } else {
                setAddedSecondModifiers((prev) => [...prev, option]);
            }
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const ModifierPaper = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#ffffff",
        borderRadius: "8px",
    }));

    const VariationPaper = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#ffffff",
        borderRadius: "8px",
    }));

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullWidth
                sx={{
                    "& .MuiPaper-root": {
                        "@media (max-width: 900px)": {
                            maxHeight: "90vh", // Apply maxHeight only for mobile screens
                        },
                        borderRadius: "20px",
                    },
                }}
                className="model-backdrop"
            >
                <DialogTitle
                    sx={{ margin: "5px" }}
                    id="customized-dialog-title"
                    className="sm-location-title"
                >
                    Select Items
                </DialogTitle>
                <Divider />
                <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        marginTop: "3px",
                        backgroundColor: "white",
                        zIndex: 2,
                        borderBottom: "1px solid #ccc",
                    }}
                >
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel
                                    StepIconProps={{
                                        style: {
                                            color:
                                                index < activeStep
                                                    ? "#4CAF50" // Green for completed steps
                                                    : index === activeStep
                                                    ? "#000" // Black for the current step
                                                    : "#ccc", // Grey for upcoming steps
                                        },
                                    }}
                                    sx={{
                                        fontSize: {
                                            xs: "0.7rem", // Smaller font for mobile (≤600px)
                                            sm: "0.9rem", // Normal font for tablets & desktops
                                        },
                                        "& .MuiStepLabel-label": {
                                            fontSize: {
                                                xs: "0.7rem", // Smaller text for step labels on mobile
                                                sm: "0.9rem", // Normal text for larger screens
                                            },
                                        },
                                    }}
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <DialogContent sx={{ padding: "10px 10px" }}>
                    <Box>
                        {activeStep === 0 && (
                            <Box>
                                {bogoCategory.map((category, index) => (
                                    <React.Fragment key={index}>
                                        {(category.items.length > 0 ||
                                            (category.subCategories.length > 0 &&
                                                category.subCategories.some(
                                                    (sub) => sub.items.length > 0
                                                ))) && (
                                            <h5
                                                className={`divider line one-line category-header title1`}
                                                // ref={(el) =>
                                                //     (categoryRefs.current[category.refId] = el)
                                                // }
                                                // onClick={() => {
                                                //     if (category.subCategories.length === 0) {
                                                //         toggleCategory(category.refId);
                                                //     }
                                                // }}
                                                // style={{
                                                //     cursor:
                                                //         category.subCategories.length === 0
                                                //             ? "pointer"
                                                //             : "default",
                                                // }}
                                            >
                                                <span className="bogo-cat-title">
                                                    {category.title}
                                                    {/* {category.subCategories.length === 0 &&
                                                    (expandedCategories[category.refId]
                                                        ? " ▼"
                                                        : " ►")} */}
                                                </span>
                                            </h5>
                                        )}
                                        <List
                                            sx={{
                                                padding: "1px",
                                            }}
                                        >
                                            {category.items.length > 0 &&
                                                category.items.map((item, index) => (
                                                    <ListItem
                                                        button
                                                        key={index}
                                                        onClick={() => {
                                                            handlePizzaSelection(
                                                                item,
                                                                true,
                                                                category.offerid
                                                            );
                                                            handleListItemClick(item.refId, true);
                                                        }}
                                                        sx={{
                                                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                            border:
                                                                firstItemSelectionStyle ===
                                                                item.refId
                                                                    ? "2px solid #006630"
                                                                    : "2px solid #dedede",
                                                            borderRadius: "10px",
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ width: 100 }}
                                                            // image={pizza.image}
                                                            src={
                                                                item.imgUrl &&
                                                                item.imgUrl !== null &&
                                                                item.imgUrl !== ""
                                                                    ? item.imgUrl
                                                                    : dummy
                                                            }
                                                            onError={(e) => {
                                                                e.target.src = dummy;
                                                            }}
                                                            alt="Product Image"
                                                            // onClick={() =>
                                                            //     addProductToCart(
                                                            //         item,
                                                            //         item.price
                                                            //             ? item.price
                                                            //             : item?.optionGroups.find(
                                                            //                   (el) =>
                                                            //                       el.refId ==
                                                            //                       item.refId
                                                            //               )?.options[0].price
                                                            //     )
                                                            // }
                                                            style={{ cursor: "pointer" }}
                                                            loading="lazy"
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: "1",
                                                            }}
                                                        >
                                                            <CardContent
                                                                sx={{
                                                                    padding: "5px",
                                                                    paddingBottom: "5px !important",
                                                                }}
                                                            >
                                                                <div className="d-flex align-items-center mb-2">
                                                                    <div
                                                                        className={
                                                                            item.foodType == 1
                                                                                ? "veg-flag"
                                                                                : "non-vegflag"
                                                                        }
                                                                    >
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    // onClick={() =>
                                                                    //     addProductToCart(
                                                                    //         item,
                                                                    //         item.price
                                                                    //             ? item.price
                                                                    //             : item?.optionGroups.find(
                                                                    //                   (el) =>
                                                                    //                       el.refId ==
                                                                    //                       item.refId
                                                                    //               )?.options[0].price
                                                                    //     )
                                                                    // }
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Typography
                                                                        variant="h4"
                                                                        className="pro-title"
                                                                    >
                                                                        {item.title}
                                                                    </Typography>
                                                                </div>
                                                                <div
                                                                    // onClick={() =>
                                                                    //     addProductToCart(
                                                                    //         item,
                                                                    //         item.price
                                                                    //             ? item.price
                                                                    //             : item?.optionGroups.find(
                                                                    //                   (el) =>
                                                                    //                       el.refId ==
                                                                    //                       item.refId
                                                                    //               )?.options[0].price
                                                                    //     )
                                                                    // }
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Typography
                                                                        variant="body1"
                                                                        className="product-price"
                                                                    >
                                                                        {getStore?.currencysymbol}{" "}
                                                                        {item.price
                                                                            ? item.price
                                                                            : item?.optionGroups.find(
                                                                                  (el) =>
                                                                                      el.refId ==
                                                                                      item.refId
                                                                              )?.options[0].price}
                                                                    </Typography>
                                                                </div>
                                                                <div>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className="product-description"
                                                                    >
                                                                        {expandedDescriptions[
                                                                            item.refId
                                                                        ]
                                                                            ? item.description
                                                                            : item.description
                                                                                  ?.length > 75
                                                                            ? `${item.description.slice(
                                                                                  0,
                                                                                  75
                                                                              )}...`
                                                                            : item.description}
                                                                        {item.description?.length >
                                                                            75 && (
                                                                            <span
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation(); // Prevent ListItem click
                                                                                    toggleReadMore(
                                                                                        item.refId
                                                                                    );
                                                                                }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                className="read-more-home"
                                                                            >
                                                                                {expandedDescriptions[
                                                                                    item.refId
                                                                                ]
                                                                                    ? " Read Less"
                                                                                    : " Read More"}
                                                                            </span>
                                                                        )}
                                                                    </Typography>
                                                                </div>
                                                                {Object.keys(getStore).length > 0 &&
                                                                    (getStore.active == "true" ||
                                                                        getStore.active == true) &&
                                                                    isStoreOpen(
                                                                        getStore.timings
                                                                    ) && (
                                                                        <div className="product-actions">
                                                                            <div className="chips-set">
                                                                                <div>
                                                                                    {item
                                                                                        .optionGroups
                                                                                        .length >
                                                                                        0 && (
                                                                                        <Chip
                                                                                            label="customize"
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            sx={{
                                                                                                borderColor:
                                                                                                    "green",
                                                                                                color: "green",
                                                                                            }}
                                                                                            // onClick={() =>
                                                                                            //     addProductToCart(
                                                                                            //         item,
                                                                                            //         item.price
                                                                                            //             ? item.price
                                                                                            //             : item?.optionGroups.find(
                                                                                            //                   (
                                                                                            //                       el
                                                                                            //                   ) =>
                                                                                            //                       el.refId ==
                                                                                            //                       item.refId
                                                                                            //               )
                                                                                            //                   ?.options[0]
                                                                                            //                   .price
                                                                                            //     )
                                                                                            // }
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                                <div className="chips-not-avelable">
                                                                                    {(item.available !=
                                                                                        1 ||
                                                                                        item.available !=
                                                                                            true) && (
                                                                                        <Chip
                                                                                            label="Not available"
                                                                                            color="error"
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                            {/* {!(
                                                                                item.available !=
                                                                                    1 ||
                                                                                item.available !=
                                                                                    true
                                                                            ) && (
                                                                                <div className="addcart-in-de-button">
                                                                                    {isProductInCart(
                                                                                        item
                                                                                    ) ? (
                                                                                        <>
                                                                                            <IconButton className="remove-button">
                                                                                                <RemoveIcon
                                                                                                    onClick={() =>
                                                                                                        dispatch(
                                                                                                            decreaseQtyFromCatalogue(
                                                                                                                {
                                                                                                                    refId: item.refId,
                                                                                                                }
                                                                                                            )
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </IconButton>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                style={{
                                                                                                    width: "30px",
                                                                                                    textAlign:
                                                                                                        "center",
                                                                                                    backgroundColor:
                                                                                                        "#EFEFEF",
                                                                                                    borderRadius:
                                                                                                        "10px",
                                                                                                    margin: "0px 4px",
                                                                                                    padding:
                                                                                                        "8px 0px",
                                                                                                }}
                                                                                            >
                                                                                                {getProductQuantityInCart(
                                                                                                    item
                                                                                                )}
                                                                                            </Typography>
                                                                                            <IconButton
                                                                                                color="primary"
                                                                                                className="add-button"
                                                                                            >
                                                                                                <AddIcon />
                                                                                            </IconButton>
                                                                                        </>
                                                                                    ) : (
                                                                                        <IconButton
                                                                                            color="primary"
                                                                                            className="add-button"
                                                                                        >
                                                                                            <AddIcon />
                                                                                        </IconButton>
                                                                                    )}
                                                                                </div>
                                                                            )} */}
                                                                        </div>
                                                                    )}
                                                            </CardContent>
                                                        </Box>
                                                    </ListItem>
                                                ))}
                                            {category.subCategories.length > 0 &&
                                                category.subCategories.map(
                                                    (subcategory, index) =>
                                                        subcategory.items.length > 0 && (
                                                            <React.Fragment key={index}>
                                                                <h5
                                                                    className="divider line one-line category-header title2"
                                                                    // ref={el => categoryRefs.current[subcategory.refId] = el}
                                                                    // onClick={() => toggleSubCategory(subcategory.refId)}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <span className="bogo-subcat-title">
                                                                        {subcategory.title}
                                                                        {/* {expandedSubCategories[subcategory.refId] ? " ▼" : " ►"} */}
                                                                    </span>
                                                                </h5>
                                                                {
                                                                    // expandedSubCategories[subcategory.refId] &&
                                                                    subcategory.items.map(
                                                                        (item, index) => (
                                                                            <ListItem
                                                                                button
                                                                                key={index}
                                                                                onClick={() => {
                                                                                    handlePizzaSelection(
                                                                                        item,
                                                                                        true,
                                                                                        category.offerid
                                                                                    );
                                                                                    handleListItemClick(
                                                                                        item.refId,
                                                                                        true
                                                                                    );
                                                                                }}
                                                                                sx={{
                                                                                    backgroundColor:
                                                                                        "rgba(0, 0, 0, 0.04)",
                                                                                    border:
                                                                                        firstItemSelectionStyle ===
                                                                                        item.refId
                                                                                            ? "2px solid #006630"
                                                                                            : "2px solid #dedede",
                                                                                    borderRadius:
                                                                                        "10px",
                                                                                    marginBottom:
                                                                                        "5px",
                                                                                }}
                                                                            >
                                                                                <CardMedia
                                                                                    component="img"
                                                                                    sx={{
                                                                                        width: 100,
                                                                                    }}
                                                                                    // image={pizza.image}
                                                                                    src={
                                                                                        item.imgUrl &&
                                                                                        item.imgUrl !==
                                                                                            null &&
                                                                                        item.imgUrl !==
                                                                                            ""
                                                                                            ? item.imgUrl
                                                                                            : dummy
                                                                                    }
                                                                                    onError={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target.src =
                                                                                            dummy;
                                                                                    }}
                                                                                    alt="Product Image"
                                                                                    // onClick={() =>
                                                                                    //     addProductToCart(
                                                                                    //         item,
                                                                                    //         item.price
                                                                                    //             ? item.price
                                                                                    //             : item?.optionGroups.find(
                                                                                    //                   (
                                                                                    //                       el
                                                                                    //                   ) =>
                                                                                    //                       el.refId ==
                                                                                    //                       item.refId
                                                                                    //               )
                                                                                    //                   ?.options[0]
                                                                                    //                   .price
                                                                                    //     )
                                                                                    // }
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    loading="lazy"
                                                                                />
                                                                                <Box
                                                                                    sx={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                        flex: "1",
                                                                                    }}
                                                                                >
                                                                                    <CardContent
                                                                                        sx={{
                                                                                            padding:
                                                                                                "5px",
                                                                                            paddingBottom:
                                                                                                "5px !important",
                                                                                        }}
                                                                                    >
                                                                                        <div className="d-flex align-items-center mb-2">
                                                                                            <div
                                                                                                className={
                                                                                                    item.foodType ==
                                                                                                    1
                                                                                                        ? "veg-flag"
                                                                                                        : "non-vegflag"
                                                                                                }
                                                                                            >
                                                                                                <span></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            // onClick={() =>
                                                                                            //     addProductToCart(
                                                                                            //         item,
                                                                                            //         item.price
                                                                                            //             ? item.price
                                                                                            //             : item?.optionGroups.find(
                                                                                            //                   (
                                                                                            //                       el
                                                                                            //                   ) =>
                                                                                            //                       el.refId ==
                                                                                            //                       item.refId
                                                                                            //               )
                                                                                            //                   ?.options[0]
                                                                                            //                   .price
                                                                                            //     )
                                                                                            // }
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                variant="h4"
                                                                                                className="pro-title"
                                                                                            >
                                                                                                {
                                                                                                    item.title
                                                                                                }
                                                                                            </Typography>
                                                                                        </div>
                                                                                        <div
                                                                                            // onClick={() =>
                                                                                            //     addProductToCart(
                                                                                            //         item,
                                                                                            //         item.price
                                                                                            //             ? item.price
                                                                                            //             : item?.optionGroups.find(
                                                                                            //                   (
                                                                                            //                       el
                                                                                            //                   ) =>
                                                                                            //                       el.refId ==
                                                                                            //                       item.refId
                                                                                            //               )
                                                                                            //                   ?.options[0]
                                                                                            //                   .price
                                                                                            //     )
                                                                                            // }
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                className="product-price"
                                                                                            >
                                                                                                {
                                                                                                    getStore?.currencysymbol
                                                                                                }{" "}
                                                                                                {item.price
                                                                                                    ? item.price
                                                                                                    : item?.optionGroups.find(
                                                                                                          (
                                                                                                              el
                                                                                                          ) =>
                                                                                                              el.refId ==
                                                                                                              item.refId
                                                                                                      )
                                                                                                          ?.options[0]
                                                                                                          .price}
                                                                                            </Typography>
                                                                                        </div>
                                                                                        <div>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                className="product-description"
                                                                                            >
                                                                                                {expandedDescriptions[
                                                                                                    item
                                                                                                        .refId
                                                                                                ]
                                                                                                    ? item.description
                                                                                                    : item
                                                                                                          .description
                                                                                                          ?.length >
                                                                                                      75
                                                                                                    ? `${item.description.slice(
                                                                                                          0,
                                                                                                          75
                                                                                                      )}...`
                                                                                                    : item.description}
                                                                                                {item
                                                                                                    .description
                                                                                                    ?.length >
                                                                                                    75 && (
                                                                                                    <span
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            e.stopPropagation(); // Prevent ListItem click
                                                                                                            toggleReadMore(
                                                                                                                item.refId
                                                                                                            );
                                                                                                        }}
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                        className="read-more-home"
                                                                                                    >
                                                                                                        {expandedDescriptions[
                                                                                                            item
                                                                                                                .refId
                                                                                                        ]
                                                                                                            ? " Read Less"
                                                                                                            : " Read More"}
                                                                                                    </span>
                                                                                                )}
                                                                                            </Typography>
                                                                                        </div>
                                                                                        {Object.keys(
                                                                                            getStore
                                                                                        ).length >
                                                                                            0 &&
                                                                                            (getStore.active ==
                                                                                                "true" ||
                                                                                                getStore.active ==
                                                                                                    true) &&
                                                                                            isStoreOpen(
                                                                                                getStore.timings
                                                                                            ) && (
                                                                                                <div className="product-actions">
                                                                                                    {/* {item.optionGroups.length > 0 && <Button
                                                                                    variant="outlined"
                                                                                    className="customize-button"
                                                                                >
                                                                                    Customize
                                                                                </Button>} */}
                                                                                                    <div className="chips-set">
                                                                                                        <div>
                                                                                                            {item
                                                                                                                .optionGroups
                                                                                                                .length >
                                                                                                                0 && (
                                                                                                                <Chip
                                                                                                                    label="customize"
                                                                                                                    variant="outlined"
                                                                                                                    size="small"
                                                                                                                    sx={{
                                                                                                                        borderColor:
                                                                                                                            "green",
                                                                                                                        color: "green",
                                                                                                                    }}
                                                                                                                    // onClick={() =>
                                                                                                                    //     addProductToCart(
                                                                                                                    //         item,
                                                                                                                    //         item.price
                                                                                                                    //             ? item.price
                                                                                                                    //             : item?.optionGroups.find(
                                                                                                                    //                   (
                                                                                                                    //                       el
                                                                                                                    //                   ) =>
                                                                                                                    //                       el.refId ==
                                                                                                                    //                       item.refId
                                                                                                                    //               )
                                                                                                                    //                   ?.options[0]
                                                                                                                    //                   .price
                                                                                                                    //     )
                                                                                                                    // }
                                                                                                                    style={{
                                                                                                                        cursor: "pointer",
                                                                                                                    }}
                                                                                                                />
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div className="chips-not-avelable">
                                                                                                            {(item.available !=
                                                                                                                1 ||
                                                                                                                item.available !=
                                                                                                                    true) && (
                                                                                                                <Chip
                                                                                                                    label="Not available"
                                                                                                                    color="error"
                                                                                                                    variant="outlined"
                                                                                                                    size="small"
                                                                                                                />
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <IconButton
                                                                                    color="primary"
                                                                                    className="add-button"
                                                                                    onClick={()=>addProductToCart(item, item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price)}
                                                                                >
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                                <IconButton style={{ color: '#fff', backgroundColor: '#000' }} >
                                                                                    <RemoveIcon />
                                                                                </IconButton>
                                                                                <Typography variant="body1" style={{ width: '30px', textAlign: 'center', backgroundColor: '#EFEFEF', borderRadius: '10px', margin:'0px 10px', padding: '10px 0px', }}>25</Typography>
                                                                                <IconButton  color="primary"
                                                                                    className="add-button"
                                                                                    onClick={()=>addProductToCart(item, item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price)}>
                                                                                    <AddIcon />
                                                                                </IconButton> */}
                                                                                                </div>
                                                                                            )}
                                                                                    </CardContent>
                                                                                </Box>
                                                                            </ListItem>
                                                                        )
                                                                    )
                                                                }
                                                            </React.Fragment>
                                                        )
                                                )}
                                        </List>
                                    </React.Fragment>
                                ))}
                                {/* <List>
                                {pizzas.map((pizza) => (
                                    <ListItem
                                        button
                                        key={pizza.id}
                                        onClick={() => handlePizzaSelection(pizza, true)}
                                    >
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 100 }}
                                            // image={pizza.image}
                                            src="/slice.png"
                                            alt={pizza.name}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: "1",
                                            }}
                                        >
                                            <CardContent>
                                                <Typography variant="h5">{pizza.name}</Typography>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="text.secondary"
                                                >
                                                    ₹{pizza.price}
                                                </Typography>
                                            </CardContent>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List> */}
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box>
                                {firstItemportions.length > 0 ||
                                selectedFirstModifiers.length > 0 ? (
                                    <>
                                        {firstItemportions.length > 0 && (
                                            <div className="vriyebal-name">
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    className="variation-style"
                                                >
                                                    Variants
                                                </Typography>
                                            </div>
                                        )}
                                        {firstItemportions.length > 0 && (
                                            <div className="veriant-main">
                                                <RadioGroup
                                                    aria-label="portion"
                                                    name="portion"
                                                    value={selectedFirstPortion.refId}
                                                >
                                                    <Grid container spacing={1}>
                                                        {firstItemportions &&
                                                            firstItemportions.map((portion) => (
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    key={portion.refId}
                                                                >
                                                                    <VariationPaper
                                                                        elevation={1}
                                                                        key={portion.refId}
                                                                        onClick={() =>
                                                                            handlePortionChange(
                                                                                portion.refId
                                                                            )
                                                                        }
                                                                        className={`portion-tile ${
                                                                            selectedFirstPortion.refId ===
                                                                            portion.refId
                                                                                ? "selected"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <Typography
                                                                            align="center"
                                                                            className="portion-title-dm"
                                                                            sx={{
                                                                                fontSize: {
                                                                                    xs: "0.7rem", // Smaller text for mobile
                                                                                    sm: "0.9rem", // Normal text for larger screens
                                                                                },
                                                                            }}
                                                                        >
                                                                            {portion.title}
                                                                        </Typography>
                                                                        <Typography
                                                                            align="center"
                                                                            sx={{
                                                                                fontSize: "0.9rem",
                                                                            }}
                                                                        >
                                                                            {
                                                                                getStore?.currencysymbol
                                                                            }{" "}
                                                                            {portion.price}
                                                                        </Typography>
                                                                    </VariationPaper>
                                                                </Grid>
                                                            ))}
                                                    </Grid>
                                                </RadioGroup>
                                            </div>
                                        )}

                                        {selectedFirstModifiers.length > 0 && (
                                            <div className="vriyebal-name">
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    className="add-ons-dialogue"
                                                >
                                                    Add ons
                                                </Typography>
                                            </div>
                                        )}
                                        {selectedFirstModifiers
                                            .sort((a, b) => a.sortOrder - b.sortOrder)
                                            .map((modifierGroup) => (
                                                <div className="setbgpor" key={modifierGroup.refId}>
                                                    <div className="set-addportion-box">
                                                        <div className="addportion-t-box new-seprator addon-box">
                                                            <div className="new-seprators">
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    gutterBottom
                                                                >
                                                                    {modifierGroup.title}
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    gutterBottom
                                                                >
                                                                    You can choose up to{" "}
                                                                    {modifierGroup.maxSelectable}{" "}
                                                                    option(s)
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {modifierGroup.options.map((option) => {
                                                        const isAvailable =
                                                            option.available === true ||
                                                            option.available === "true";

                                                        return (
                                                            <ModifierPaper
                                                                elevation={1}
                                                                key={option.refId}
                                                                onClick={() =>
                                                                    isAvailable &&
                                                                    handleModifierChange(option)
                                                                }
                                                                className={`modifier-tile ${
                                                                    addedFirstModifiers.some(
                                                                        (modifier) =>
                                                                            modifier.refId ===
                                                                                option.refId &&
                                                                            isAvailable
                                                                    )
                                                                        ? "selected"
                                                                        : ""
                                                                }`}
                                                                style={{
                                                                    // userSelect: "none", // Prevents text selection
                                                                    // outline: "none",    // Removes focus outline
                                                                    WebkitTapHighlightColor:
                                                                        "transparent", // Removes blue highlight on touch
                                                                    opacity: isAvailable ? 1 : 0.6, // Make disabled options slightly faded
                                                                    cursor: isAvailable
                                                                        ? "pointer"
                                                                        : "not-allowed",
                                                                }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    alignItems="center"
                                                                    key={option.refId}
                                                                    style={{ padding: "7px" }}
                                                                >
                                                                    <Grid item xs={8}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "0.9rem",
                                                                                textAlign: "left",
                                                                            }}
                                                                        >
                                                                            {option.title}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography align="right">
                                                                            {
                                                                                getStore?.currencysymbol
                                                                            }{" "}
                                                                            {option.price}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Checkbox
                                                                            checked={addedFirstModifiers.some(
                                                                                (modifier) =>
                                                                                    modifier.refId ===
                                                                                        option.refId &&
                                                                                    isAvailable
                                                                            )}
                                                                            value={option.title}
                                                                            disabled={!isAvailable}
                                                                            // onChange={() => handleModifierChange(option)}
                                                                            // disabled={
                                                                            //     portions.length === 0 &&
                                                                            //     selectedModifiers[0].maxSelectable ===
                                                                            //         selectedModifiers[0].minSelectable
                                                                            // }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </ModifierPaper>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                    </>
                                ) : (
                                    <Typography
                                        align="center"
                                        sx={{ mt: 2, fontSize: "1rem", color: "gray" }}
                                    >
                                        Sorry, No Customization Available For This Item
                                    </Typography>
                                )}
                            </Box>
                        )}

                        {activeStep === 2 && (
                            <Box>
                                {bogoCategory.map((category, index) => (
                                    <React.Fragment key={index}>
                                        {(category.items.length > 0 ||
                                            (category.subCategories.length > 0 &&
                                                category.subCategories.some(
                                                    (sub) => sub.items.length > 0
                                                ))) && (
                                            <h5
                                                className={`divider line one-line category-header title1`}
                                                // ref={(el) =>
                                                //     (categoryRefs.current[category.refId] = el)
                                                // }
                                                // onClick={() => {
                                                //     if (category.subCategories.length === 0) {
                                                //         toggleCategory(category.refId);
                                                //     }
                                                // }}
                                                // style={{
                                                //     cursor:
                                                //         category.subCategories.length === 0
                                                //             ? "pointer"
                                                //             : "default",
                                                // }}
                                            >
                                                <span className="bogo-cat-title">
                                                    {category.title}
                                                    {/* {category.subCategories.length === 0 &&
                                                    (expandedCategories[category.refId]
                                                        ? " ▼"
                                                        : " ►")} */}
                                                </span>
                                            </h5>
                                        )}
                                        <List
                                            sx={{
                                                padding: "1px",
                                            }}
                                        >
                                            {category.items.length > 0 &&
                                                category.items.map((item, index) => (
                                                    <ListItem
                                                        button
                                                        key={index}
                                                        onClick={() => {
                                                            handlePizzaSelection(
                                                                item,
                                                                false,
                                                                category.offerid
                                                            );
                                                            handleListItemClick(item.refId, false);
                                                        }}
                                                        sx={{
                                                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                            border:
                                                                secondItemSelectionStyle ===
                                                                item.refId
                                                                    ? "2px solid #006630"
                                                                    : "2px solid #dedede",
                                                            borderRadius: "10px",
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ width: 100 }}
                                                            // image={pizza.image}
                                                            src={
                                                                item.imgUrl &&
                                                                item.imgUrl !== null &&
                                                                item.imgUrl !== ""
                                                                    ? item.imgUrl
                                                                    : dummy
                                                            }
                                                            onError={(e) => {
                                                                e.target.src = dummy;
                                                            }}
                                                            alt="Product Image"
                                                            // onClick={() =>
                                                            //     addProductToCart(
                                                            //         item,
                                                            //         item.price
                                                            //             ? item.price
                                                            //             : item?.optionGroups.find(
                                                            //                   (el) =>
                                                            //                       el.refId ==
                                                            //                       item.refId
                                                            //               )?.options[0].price
                                                            //     )
                                                            // }
                                                            style={{ cursor: "pointer" }}
                                                            loading="lazy"
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flex: "1",
                                                            }}
                                                        >
                                                            <CardContent
                                                                sx={{
                                                                    padding: "5px",
                                                                    paddingBottom: "5px !important",
                                                                }}
                                                            >
                                                                <div className="d-flex align-items-center mb-2">
                                                                    <div
                                                                        className={
                                                                            item.foodType == 1
                                                                                ? "veg-flag"
                                                                                : "non-vegflag"
                                                                        }
                                                                    >
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    // onClick={() =>
                                                                    //     addProductToCart(
                                                                    //         item,
                                                                    //         item.price
                                                                    //             ? item.price
                                                                    //             : item?.optionGroups.find(
                                                                    //                   (el) =>
                                                                    //                       el.refId ==
                                                                    //                       item.refId
                                                                    //               )?.options[0].price
                                                                    //     )
                                                                    // }
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Typography
                                                                        variant="h4"
                                                                        className="pro-title"
                                                                    >
                                                                        {item.title}
                                                                    </Typography>
                                                                </div>
                                                                <div
                                                                    // onClick={() =>
                                                                    //     addProductToCart(
                                                                    //         item,
                                                                    //         item.price
                                                                    //             ? item.price
                                                                    //             : item?.optionGroups.find(
                                                                    //                   (el) =>
                                                                    //                       el.refId ==
                                                                    //                       item.refId
                                                                    //               )?.options[0].price
                                                                    //     )
                                                                    // }
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Typography
                                                                        variant="body1"
                                                                        className="product-price"
                                                                    >
                                                                        {getStore?.currencysymbol}{" "}
                                                                        {item.price
                                                                            ? item.price
                                                                            : item?.optionGroups.find(
                                                                                  (el) =>
                                                                                      el.refId ==
                                                                                      item.refId
                                                                              )?.options[0].price}
                                                                    </Typography>
                                                                </div>
                                                                <div>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className="product-description"
                                                                    >
                                                                        {expandedDescriptions[
                                                                            item.refId
                                                                        ]
                                                                            ? item.description
                                                                            : item.description
                                                                                  ?.length > 75
                                                                            ? `${item.description.slice(
                                                                                  0,
                                                                                  75
                                                                              )}...`
                                                                            : item.description}
                                                                        {item.description?.length >
                                                                            75 && (
                                                                            <span
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation(); // Prevent ListItem click
                                                                                    toggleReadMore(
                                                                                        item.refId
                                                                                    );
                                                                                }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                className="read-more-home"
                                                                            >
                                                                                {expandedDescriptions[
                                                                                    item.refId
                                                                                ]
                                                                                    ? " Read Less"
                                                                                    : " Read More"}
                                                                            </span>
                                                                        )}
                                                                    </Typography>
                                                                </div>
                                                                {Object.keys(getStore).length > 0 &&
                                                                    (getStore.active == "true" ||
                                                                        getStore.active == true) &&
                                                                    isStoreOpen(
                                                                        getStore.timings
                                                                    ) && (
                                                                        <div className="product-actions">
                                                                            <div className="chips-set">
                                                                                <div>
                                                                                    {item
                                                                                        .optionGroups
                                                                                        .length >
                                                                                        0 && (
                                                                                        <Chip
                                                                                            label="customize"
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            sx={{
                                                                                                borderColor:
                                                                                                    "green",
                                                                                                color: "green",
                                                                                            }}
                                                                                            // onClick={() =>
                                                                                            //     addProductToCart(
                                                                                            //         item,
                                                                                            //         item.price
                                                                                            //             ? item.price
                                                                                            //             : item?.optionGroups.find(
                                                                                            //                   (
                                                                                            //                       el
                                                                                            //                   ) =>
                                                                                            //                       el.refId ==
                                                                                            //                       item.refId
                                                                                            //               )
                                                                                            //                   ?.options[0]
                                                                                            //                   .price
                                                                                            //     )
                                                                                            // }
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                                <div className="chips-not-avelable">
                                                                                    {(item.available !=
                                                                                        1 ||
                                                                                        item.available !=
                                                                                            true) && (
                                                                                        <Chip
                                                                                            label="Not available"
                                                                                            color="error"
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </CardContent>
                                                        </Box>
                                                    </ListItem>
                                                ))}
                                            {category.subCategories.length > 0 &&
                                                category.subCategories.map(
                                                    (subcategory, index) =>
                                                        subcategory.items.length > 0 && (
                                                            <React.Fragment key={index}>
                                                                <h5
                                                                    className="divider line one-line category-header title2"
                                                                    // ref={el => categoryRefs.current[subcategory.refId] = el}
                                                                    // onClick={() => toggleSubCategory(subcategory.refId)}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <span className="bogo-subcat-title">
                                                                        {subcategory.title}
                                                                        {/* {expandedSubCategories[subcategory.refId] ? " ▼" : " ►"} */}
                                                                    </span>
                                                                </h5>
                                                                {
                                                                    // expandedSubCategories[subcategory.refId] &&
                                                                    subcategory.items.map(
                                                                        (item, index) => (
                                                                            <ListItem
                                                                                button
                                                                                key={index}
                                                                                onClick={() => {
                                                                                    handlePizzaSelection(
                                                                                        item,
                                                                                        false,
                                                                                        category.offerid
                                                                                    );
                                                                                    handleListItemClick(
                                                                                        item.refId,
                                                                                        false
                                                                                    );
                                                                                }}
                                                                                sx={{
                                                                                    backgroundColor:
                                                                                        "rgba(0, 0, 0, 0.04)",
                                                                                    border:
                                                                                        secondItemSelectionStyle ===
                                                                                        item.refId
                                                                                            ? "2px solid #006630"
                                                                                            : "2px solid #dedede",
                                                                                    borderRadius:
                                                                                        "10px",
                                                                                    marginBottom:
                                                                                        "5px",
                                                                                }}
                                                                            >
                                                                                <CardMedia
                                                                                    component="img"
                                                                                    sx={{
                                                                                        width: 100,
                                                                                    }}
                                                                                    // image={pizza.image}
                                                                                    src={
                                                                                        item.imgUrl &&
                                                                                        item.imgUrl !==
                                                                                            null &&
                                                                                        item.imgUrl !==
                                                                                            ""
                                                                                            ? item.imgUrl
                                                                                            : dummy
                                                                                    }
                                                                                    onError={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.target.src =
                                                                                            dummy;
                                                                                    }}
                                                                                    alt="Product Image"
                                                                                    // onClick={() =>
                                                                                    //     addProductToCart(
                                                                                    //         item,
                                                                                    //         item.price
                                                                                    //             ? item.price
                                                                                    //             : item?.optionGroups.find(
                                                                                    //                   (
                                                                                    //                       el
                                                                                    //                   ) =>
                                                                                    //                       el.refId ==
                                                                                    //                       item.refId
                                                                                    //               )
                                                                                    //                   ?.options[0]
                                                                                    //                   .price
                                                                                    //     )
                                                                                    // }
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    loading="lazy"
                                                                                />
                                                                                <Box
                                                                                    sx={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                        flex: "1",
                                                                                    }}
                                                                                >
                                                                                    <CardContent
                                                                                        sx={{
                                                                                            padding:
                                                                                                "5px",
                                                                                            paddingBottom:
                                                                                                "5px !important",
                                                                                        }}
                                                                                    >
                                                                                        <div className="d-flex align-items-center mb-2">
                                                                                            <div
                                                                                                className={
                                                                                                    item.foodType ==
                                                                                                    1
                                                                                                        ? "veg-flag"
                                                                                                        : "non-vegflag"
                                                                                                }
                                                                                            >
                                                                                                <span></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            // onClick={() =>
                                                                                            //     addProductToCart(
                                                                                            //         item,
                                                                                            //         item.price
                                                                                            //             ? item.price
                                                                                            //             : item?.optionGroups.find(
                                                                                            //                   (
                                                                                            //                       el
                                                                                            //                   ) =>
                                                                                            //                       el.refId ==
                                                                                            //                       item.refId
                                                                                            //               )
                                                                                            //                   ?.options[0]
                                                                                            //                   .price
                                                                                            //     )
                                                                                            // }
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                variant="h4"
                                                                                                className="pro-title"
                                                                                            >
                                                                                                {
                                                                                                    item.title
                                                                                                }
                                                                                            </Typography>
                                                                                        </div>
                                                                                        <div
                                                                                            // onClick={() =>
                                                                                            //     addProductToCart(
                                                                                            //         item,
                                                                                            //         item.price
                                                                                            //             ? item.price
                                                                                            //             : item?.optionGroups.find(
                                                                                            //                   (
                                                                                            //                       el
                                                                                            //                   ) =>
                                                                                            //                       el.refId ==
                                                                                            //                       item.refId
                                                                                            //               )
                                                                                            //                   ?.options[0]
                                                                                            //                   .price
                                                                                            //     )
                                                                                            // }
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                className="product-price"
                                                                                            >
                                                                                                {
                                                                                                    getStore?.currencysymbol
                                                                                                }{" "}
                                                                                                {item.price
                                                                                                    ? item.price
                                                                                                    : item?.optionGroups.find(
                                                                                                          (
                                                                                                              el
                                                                                                          ) =>
                                                                                                              el.refId ==
                                                                                                              item.refId
                                                                                                      )
                                                                                                          ?.options[0]
                                                                                                          .price}
                                                                                            </Typography>
                                                                                        </div>
                                                                                        <div>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                className="product-description"
                                                                                            >
                                                                                                {expandedDescriptions[
                                                                                                    item
                                                                                                        .refId
                                                                                                ]
                                                                                                    ? item.description
                                                                                                    : item
                                                                                                          .description
                                                                                                          ?.length >
                                                                                                      75
                                                                                                    ? `${item.description.slice(
                                                                                                          0,
                                                                                                          75
                                                                                                      )}...`
                                                                                                    : item.description}
                                                                                                {item
                                                                                                    .description
                                                                                                    ?.length >
                                                                                                    75 && (
                                                                                                    <span
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            e.stopPropagation(); // Prevent ListItem click
                                                                                                            toggleReadMore(
                                                                                                                item.refId
                                                                                                            );
                                                                                                        }}
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                        className="read-more-home"
                                                                                                    >
                                                                                                        {expandedDescriptions[
                                                                                                            item
                                                                                                                .refId
                                                                                                        ]
                                                                                                            ? " Read Less"
                                                                                                            : " Read More"}
                                                                                                    </span>
                                                                                                )}
                                                                                            </Typography>
                                                                                        </div>
                                                                                        {Object.keys(
                                                                                            getStore
                                                                                        ).length >
                                                                                            0 &&
                                                                                            (getStore.active ==
                                                                                                "true" ||
                                                                                                getStore.active ==
                                                                                                    true) &&
                                                                                            isStoreOpen(
                                                                                                getStore.timings
                                                                                            ) && (
                                                                                                <div className="product-actions">
                                                                                                    {/* {item.optionGroups.length > 0 && <Button
                                                                                    variant="outlined"
                                                                                    className="customize-button"
                                                                                >
                                                                                    Customize
                                                                                </Button>} */}
                                                                                                    <div className="chips-set">
                                                                                                        <div>
                                                                                                            {item
                                                                                                                .optionGroups
                                                                                                                .length >
                                                                                                                0 && (
                                                                                                                <Chip
                                                                                                                    label="customize"
                                                                                                                    variant="outlined"
                                                                                                                    size="small"
                                                                                                                    sx={{
                                                                                                                        borderColor:
                                                                                                                            "green",
                                                                                                                        color: "green",
                                                                                                                    }}
                                                                                                                    // onClick={() =>
                                                                                                                    //     addProductToCart(
                                                                                                                    //         item,
                                                                                                                    //         item.price
                                                                                                                    //             ? item.price
                                                                                                                    //             : item?.optionGroups.find(
                                                                                                                    //                   (
                                                                                                                    //                       el
                                                                                                                    //                   ) =>
                                                                                                                    //                       el.refId ==
                                                                                                                    //                       item.refId
                                                                                                                    //               )
                                                                                                                    //                   ?.options[0]
                                                                                                                    //                   .price
                                                                                                                    //     )
                                                                                                                    // }
                                                                                                                    style={{
                                                                                                                        cursor: "pointer",
                                                                                                                    }}
                                                                                                                />
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div className="chips-not-avelable">
                                                                                                            {(item.available !=
                                                                                                                1 ||
                                                                                                                item.available !=
                                                                                                                    true) && (
                                                                                                                <Chip
                                                                                                                    label="Not available"
                                                                                                                    color="error"
                                                                                                                    variant="outlined"
                                                                                                                    size="small"
                                                                                                                />
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <IconButton
                                                                                    color="primary"
                                                                                    className="add-button"
                                                                                    onClick={()=>addProductToCart(item, item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price)}
                                                                                >
                                                                                    <AddIcon />
                                                                                </IconButton>
                                                                                <IconButton style={{ color: '#fff', backgroundColor: '#000' }} >
                                                                                    <RemoveIcon />
                                                                                </IconButton>
                                                                                <Typography variant="body1" style={{ width: '30px', textAlign: 'center', backgroundColor: '#EFEFEF', borderRadius: '10px', margin:'0px 10px', padding: '10px 0px', }}>25</Typography>
                                                                                <IconButton  color="primary"
                                                                                    className="add-button"
                                                                                    onClick={()=>addProductToCart(item, item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price)}>
                                                                                    <AddIcon />
                                                                                </IconButton> */}
                                                                                                </div>
                                                                                            )}
                                                                                    </CardContent>
                                                                                </Box>
                                                                            </ListItem>
                                                                        )
                                                                    )
                                                                }
                                                            </React.Fragment>
                                                        )
                                                )}
                                        </List>
                                    </React.Fragment>
                                ))}
                            </Box>
                        )}

                        {activeStep === 3 && (
                            <Box>
                                {secondItemportions.length > 0 ||
                                selectedSecondModifiers.length > 0 ? (
                                    <>
                                        {secondItemportions.length > 0 && (
                                            <div className="vriyebal-name">
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    className="variation-style"
                                                >
                                                    Variants
                                                </Typography>
                                            </div>
                                        )}
                                        {secondItemportions.length > 0 && (
                                            <div className="veriant-main">
                                                <RadioGroup
                                                    aria-label="portion"
                                                    name="portion"
                                                    value={selectedSecondPortion.refId}
                                                >
                                                    <Grid container spacing={1}>
                                                        {secondItemportions &&
                                                            secondItemportions.map((portion) => (
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    key={portion.refId}
                                                                >
                                                                    <VariationPaper
                                                                        elevation={1}
                                                                        key={portion.refId}
                                                                        onClick={() =>
                                                                            handlePortionChange(
                                                                                portion.refId
                                                                            )
                                                                        }
                                                                        className={`portion-tile ${
                                                                            selectedSecondPortion.refId ===
                                                                            portion.refId
                                                                                ? "selected"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        <Typography
                                                                            align="center"
                                                                            className="portion-title-dm"
                                                                            sx={{
                                                                                fontSize: {
                                                                                    xs: "0.7rem", // Smaller text for mobile
                                                                                    sm: "0.9rem", // Normal text for larger screens
                                                                                },
                                                                            }}
                                                                        >
                                                                            {portion.title}
                                                                        </Typography>
                                                                        <Typography
                                                                            align="center"
                                                                            sx={{
                                                                                fontSize: "0.9rem",
                                                                            }}
                                                                        >
                                                                            {
                                                                                getStore?.currencysymbol
                                                                            }{" "}
                                                                            {portion.price}
                                                                        </Typography>
                                                                    </VariationPaper>
                                                                </Grid>
                                                            ))}
                                                    </Grid>
                                                </RadioGroup>
                                            </div>
                                        )}

                                        {selectedSecondModifiers.length > 0 && (
                                            <div className="vriyebal-name">
                                                <Typography
                                                    variant="subtitle1"
                                                    gutterBottom
                                                    className="add-ons-dialogue"
                                                >
                                                    Add ons
                                                </Typography>
                                            </div>
                                        )}
                                        {selectedSecondModifiers
                                            .sort((a, b) => a.sortOrder - b.sortOrder)
                                            .map((modifierGroup) => (
                                                <div className="setbgpor" key={modifierGroup.refId}>
                                                    <div className="set-addportion-box">
                                                        <div className="addportion-t-box new-seprator addon-box">
                                                            <div className="new-seprators">
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    gutterBottom
                                                                >
                                                                    {modifierGroup.title}
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    gutterBottom
                                                                >
                                                                    You can choose up to{" "}
                                                                    {modifierGroup.maxSelectable}{" "}
                                                                    option(s)
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {modifierGroup.options.map((option) => {
                                                        const isAvailable =
                                                            option.available === true ||
                                                            option.available === "true";

                                                        return (
                                                            <ModifierPaper
                                                                elevation={1}
                                                                key={option.refId}
                                                                onClick={() =>
                                                                    isAvailable &&
                                                                    handleModifierChange(option)
                                                                }
                                                                className={`modifier-tile ${
                                                                    addedSecondModifiers.some(
                                                                        (modifier) =>
                                                                            modifier.refId ===
                                                                                option.refId &&
                                                                            isAvailable
                                                                    )
                                                                        ? "selected"
                                                                        : ""
                                                                }`}
                                                                style={{
                                                                    // userSelect: "none", // Prevents text selection
                                                                    // outline: "none",    // Removes focus outline
                                                                    WebkitTapHighlightColor:
                                                                        "transparent", // Removes blue highlight on touch
                                                                    opacity: isAvailable ? 1 : 0.6, // Make disabled options slightly faded
                                                                    cursor: isAvailable
                                                                        ? "pointer"
                                                                        : "not-allowed",
                                                                }}
                                                            >
                                                                <Grid
                                                                    container
                                                                    alignItems="center"
                                                                    key={option.refId}
                                                                    style={{ padding: "7px" }}
                                                                >
                                                                    <Grid item xs={8}>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: "0.9rem",
                                                                                textAlign: "left",
                                                                            }}
                                                                        >
                                                                            {option.title}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Typography align="right">
                                                                            {
                                                                                getStore?.currencysymbol
                                                                            }{" "}
                                                                            {option.price}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Checkbox
                                                                            checked={addedSecondModifiers.some(
                                                                                (modifier) =>
                                                                                    modifier.refId ===
                                                                                        option.refId &&
                                                                                    isAvailable
                                                                            )}
                                                                            value={option.title}
                                                                            disabled={!isAvailable}
                                                                            // onChange={() => handleModifierChange(option)}
                                                                            // disabled={
                                                                            //     portions.length === 0 &&
                                                                            //     selectedModifiers[0].maxSelectable ===
                                                                            //         selectedModifiers[0].minSelectable
                                                                            // }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </ModifierPaper>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                    </>
                                ) : (
                                    <Typography
                                        align="center"
                                        sx={{ mt: 2, fontSize: "1rem", color: "gray" }}
                                    >
                                        Sorry, No Customization Available For This Item
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                        className="previous-button-setups"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="contained"
                    >
                        Previous
                    </Button>
                    {activeStep < 3 ? (
                        <Button
                            className="next-button-setups"
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                        >
                            Next
                        </Button>
                    ) : (
                        <Button variant="contained" color="success" onClick={handleNext}>
                            Order Now
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
};

export default BOGOWizard;
