import React from "react";
import AddIcon from "@mui/icons-material/Add";
import {Paper, Typography, Grid, IconButton} from "@mui/material";
import "./BOGOProductTile.css"
import dummy from "../../assets/DummyImage.png"

function BOGOProductTile({getStore, addProductToCart, category, setShowBogoPopup, isStoreOpen, setSelectedBogoCategoryId, offerData }) {
    const getMinPrice = (items) => {
        let minPrice = Infinity;
    
        items.forEach((item) => {
            let itemPrice = item.price;
        
            if (itemPrice === 0 && item.optionGroups) {
              item.optionGroups.forEach((group) => {
                if(group.refId == item.refId) {
                    group.options.forEach((option) => {
                        if (option.available && option.price > 0) {
                            minPrice = Math.min(minPrice, option.price);
                        }
                    });
                }
              });
            } else if (itemPrice > 0) {
              minPrice = Math.min(minPrice, itemPrice);
            }
          });
    
        return minPrice === Infinity ? 0 : minPrice;
    };
    
    // Get all items from the category and its subcategories
    const allItems = [
        ...(category.items || []),
        ...((category.subCategories || []).flatMap(sub => sub.items || []))
    ];

    // Find the lowest price
    const BOGOItemPrice = getMinPrice(allItems);
 
    return (
        <Grid item xs={12} sm={6} md={6} lg={3} className="item-cart-category">
            <Paper className="product-card">
                <div className="product-box-wc">
                    <div
                        className="pro-contentimg"
                        onClick={() => {
                                setShowBogoPopup(true);
                                setSelectedBogoCategoryId(category.refId);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <img
                            // src={
                            //     item.imgUrl && item.imgUrl !== null && item.imgUrl !== ""
                            //         ? item.imgUrl
                            //         : dummy
                            // }
                            src={`https://billberrypos.s3.ap-south-1.amazonaws.com/${offerData.offerimage}`}
                            onError={(e) => {
                                e.target.src = dummy;
                            }}
                            alt="Product Image"
                            className="product-image"
                            loading="lazy"
                        />
                    </div>
                    <div className="pro-contentbox">
                        {/* <div className="d-flex align-items-center mb-2">
                            <div className={item.foodType == 1 ? "veg-flag" : "non-vegflag"}>
                                <span></span>
                            </div>
                        </div> */}
                        <div
                            onClick={() => {
                                setShowBogoPopup(true)
                                setSelectedBogoCategoryId(category.refId);
                            }
                            }
                            style={{ cursor: "pointer" }}
                        >
                            <Typography variant="h4" className="pro-title bogo-offername">
                                {offerData.offername || "BOGO Items"}
                            </Typography>
                        </div>
                        <div
                            onClick={() => {
                                setShowBogoPopup(true)
                                setSelectedBogoCategoryId(category.refId);
                            }
                            }
                            style={{ cursor: "pointer" }}
                        >
                            <Typography variant="body1" className="product-price">
                                {getStore?.currencysymbol}{" "}
                                {/* {item.price
                                    ? item.price
                                    : item?.optionGroups.find((el) => el.refId == item.refId)
                                          ?.options[0].price} */}
                                          {BOGOItemPrice}
                            </Typography>
                        </div>
                        {/* <div>
                            <Typography variant="body1" className="product-description">
                                {expandedDescriptions[item.refId]
                                    ? item.description
                                    : item.description?.length > 50
                                    ? `${item.description.slice(0, 50)}...`
                                    : item.description}
                                {item.description?.length > 50 && (
                                    <span
                                        onClick={() => toggleReadMore(item.refId)}
                                        style={{ cursor: "pointer" }}
                                        className="read-more-home"
                                    >
                                        {expandedDescriptions[item.refId]
                                            ? " Read Less"
                                            : " Read More"}
                                    </span>
                                )}
                            </Typography>
                        </div> */}
                    </div>
                </div>
                {Object.keys(getStore).length > 0 &&
                    (getStore.active == "true" || getStore.active == true) &&
                    isStoreOpen(getStore.timings) && (
                        <div className="product-actions">
                            <div className="chips-set">
                            </div>
                                <div className="addcart-in-de-button">
                                        <IconButton
                                            color="primary"
                                            className="add-button"
                                            onClick={() => {
                                                setShowBogoPopup(true)
                                                setSelectedBogoCategoryId(category.refId);
                                            }
                                            }
                                        >
                                            <AddIcon />
                                        </IconButton>
                                </div>
                        </div>
                    )}
            </Paper>
        </Grid>
    );
}

export default BOGOProductTile;
