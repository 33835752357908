import React, { useEffect, useState } from "react";
import { Drawer, Box, Typography } from "@mui/material";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";
import "./OfferDrawerComp.css";

function OfferDrawerComp({ isDrawerOpen, setIsDrawerOpen, offers }) {
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 600) {
                setIsDrawerOpen(false); // Auto-close the drawer
            }
        };

        window.addEventListener("resize", handleResize);

        // Cleanup event listener when component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setIsDrawerOpen]);

    const toggleReadMore = (index) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const getTrimmedDescription = (description) => {
        const sentences = description.split(".").filter((sentence) => sentence.trim().length > 0); // Remove empty entries

        let trimmedText = [];
        let charCount = 0;

        for (let i = 0; i < sentences.length; i++) {
            const sentence = sentences[i].trim();
            if (charCount + sentence.length + 2 <= 80) {
                // +2 for icon spacing
                trimmedText.push(sentence);
                charCount += sentence.length + 2;
            } else {
                trimmedText.push(`${sentence.slice(0, 80 - charCount)}...`);
                break;
            }
        }
        return trimmedText;
    };

    return (
        <Drawer
            anchor="bottom"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            PaperProps={{
                className: "custom-offer-drawer",
            }}
        >
            <Box
                className="drawer-offer-box"
                sx={{
                    textAlign: "left",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: 300,
                        overflowY: "auto",
                        overflowX: "hidden",
                        // padding: 2,
                    }}
                >
                    {offers.length > 0 ? (
                        offers.map((offer, index) => {
                            const isExpanded = expandedDescriptions[index];
                            const shouldShowReadMore = offer.offerdescription.length > 80;

                            return (
                                <Box
                                    key={index}
                                    // onClick={() => handleSelect(location)}
                                    sx={{
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                        backgroundColor: "transparent",
                                        "&:hover": {
                                            boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                                        },
                                    }}
                                >
                                    <div
                                        className=""
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            padding: "10px",
                                            border: "1px solid #ccc",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        <div className="ssl-icon" style={{ marginRight: "10px" }}>
                                            <span style={{ color: "green" }}>
                                                <LocalOfferRoundedIcon />
                                            </span>
                                        </div>
                                        <div className="ssl-content">
                                            <Typography
                                                variant="h6"
                                                className="sm-offer-name"
                                                style={{ marginBottom: "5px" }}
                                            >
                                                {offer.offername}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                className="sm-offer-address"
                                                color="textSecondary"
                                                style={{ marginBottom: "8px" }}
                                            >
                                                {isExpanded
                                                    ? offer.offerdescription
                                                          .split(".")
                                                          .filter(
                                                              (sentence) =>
                                                                  sentence.trim().length > 0
                                                          )
                                                          .map((sentence, idx, arr) => (
                                                              <Box
                                                                  key={idx}
                                                                  sx={{
                                                                      display: "flex",
                                                                      alignItems: "center",
                                                                      gap: 1,
                                                                      mb: 1,
                                                                  }}
                                                              >
                                                                  <CheckCircleIcon
                                                                      sx={{
                                                                          color: green[500],
                                                                          fontSize: 18,
                                                                      }}
                                                                  />
                                                                  <span>
                                                                      {sentence.trim()}.
                                                                      {idx === arr.length - 1 &&
                                                                          shouldShowReadMore && (
                                                                              <span
                                                                                  onClick={() =>
                                                                                      toggleReadMore(
                                                                                          index
                                                                                      )
                                                                                  }
                                                                                  className="read-more-offer"
                                                                              >
                                                                                  {isExpanded
                                                                                      ? " Read Less"
                                                                                      : " Read More"}
                                                                              </span>
                                                                          )}
                                                                  </span>
                                                              </Box>
                                                          ))
                                                    : getTrimmedDescription(
                                                          offer.offerdescription
                                                      ).map((sentence, idx, arr) => (
                                                          <Box
                                                              key={idx}
                                                              sx={{
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                                  gap: 1,
                                                                  mb: 1,
                                                              }}
                                                          >
                                                              <CheckCircleIcon
                                                                  sx={{
                                                                      color: green[500],
                                                                      fontSize: 18,
                                                                  }}
                                                              />
                                                              <span>
                                                                  {sentence}
                                                                  {idx === arr.length - 1 &&
                                                                      shouldShowReadMore && (
                                                                          <span
                                                                              onClick={() =>
                                                                                  toggleReadMore(
                                                                                      index
                                                                                  )
                                                                              }
                                                                              className="read-more-offer"
                                                                          >
                                                                              {isExpanded
                                                                                  ? " Read Less"
                                                                                  : " Read More"}
                                                                          </span>
                                                                      )}
                                                              </span>
                                                          </Box>
                                                      ))}
                                            </Typography>
                                        </div>
                                    </div>
                                </Box>
                            );
                        })
                    ) : (
                        <div className="no-search-found">
                            <Typography variant="body2">
                                {`No Result Found For `}
                                {/* <span className="no-search-found-span">"{searchQuery}"</span> */}
                            </Typography>
                        </div>
                    )}
                </Box>
            </Box>
        </Drawer>
    );
}

export default OfferDrawerComp;
