import React, { useState } from "react";
import { Skeleton, Button } from "@mui/material";
import dummy from "../../assets/offerImage.png";
import "./OfferScroller.css";

const OfferScroller = ({ offers, isLoading }) => {
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    const toggleReadMore = (index) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const renderSkeletons = () =>
        Array.from({ length: 4 }).map((_, index) => (
            <ul key={index} className="home-apply-coupon-div">
                 <li className="home-apply-coupon-div offer-skeleton">
                <Skeleton
                    variant="rectangular"
                    width={70}
                    height={60}
                    className="home-offer-image"
                    sx={{ bgcolor: "#d3d3d3" }}
                />
                <Skeleton
                    variant="text"
                    width="60%"
                    className="home-offer-name"
                    sx={{ bgcolor: "#d3d3d3" }}
                />
                {/* <Skeleton
                    variant="text"
                    width="90%"
                    height={15}
                    className="offer-description-skeleton"
                    sx={{ bgcolor: "#d3d3d3" }}
                /> */}
                </li>
            </ul>
        ));

    if (isLoading) {
        return (
            <div>
                <h2 className="offers-title divider line one-line offer-style">
                    <span>OFFERS</span>
                </h2>
                <div className="offers-scroller">{renderSkeletons()}</div>
            </div>
        );
    }

    return (
        <div>
            <h2 className="offers-title divider line one-line offer-style">
                <span>OFFERS</span>
            </h2>
            <div className="offers-scroller">
                {offers.map((offer, index) => {
                    const isExpanded = expandedDescriptions[index];
                    const shouldShowReadMore = offer.offerdescription.length > 80;

                    return (
                        <ul key={index} className="home-apply-coupon-div coupon-hover">
                            <li className="home-apply-coupon-div">
                                <img
                                    src={`https://billberrypos.s3.ap-south-1.amazonaws.com/${offer.offerimage}`}
                                    onError={(e) => {
                                        e.target.src = dummy;
                                    }}
                                    alt="offer-image"
                                    className="home-offer-image"
                                />
                                <div className="vertical-border"></div>
                                <p className="home-offer-name">{offer.offername}</p>
                            </li>
                        </ul>
                    );
                })}
            </div>
        </div>
    );
};

export default OfferScroller;
